import firebase from "firebase/compat/app";
import { rollbar } from "./rollbar";

export function docRefToArray<CollectionType>(
  docRef: firebase.firestore.QuerySnapshot
) {
  return docRef.docs.map((entry: any) => {
    return { ...entry.data(), id: entry.id };
  }) as CollectionType[];
}

export function docToObject<DocType>(doc: firebase.firestore.DocumentSnapshot) {
  if (doc.exists) {
    const data = doc.data();
    if (data) {
      data.id = doc.id;
    }
    return data as DocType;
  }

  return null;
}

export const getTimestamp = (timestamp: Date = new Date()) => {
  return {
    epoch: timestamp.getTime(),
    iso_8601_datetime_tz: timestamp.toISOString(),
  };
};

export function getCurrentUserUid() {
  const uid = firebase.auth().currentUser?.uid;

  if (uid == null) {
    const message = "Trying to query firebase without currentUser";
    rollbar.error(message);
  }

  return uid;
}

export interface GetMilliSecondsParams {
  seconds: number;
  nanoSeconds: number;
}

/**
 * @param seconds number - Provide seconds.
 * @param nanoSeconds number - Provide nanoSeconds.
 * @returns return timestamp converted by firebase timestamp function(toMillis)
 */
export const getMilliSeconds = ({
  seconds,
  nanoSeconds,
}: GetMilliSecondsParams) => {
  return new firebase.firestore.Timestamp(seconds, nanoSeconds).toMillis();
};
