import { useEffect, useState } from "react";

export const checkIsPrerender = () => {
  return navigator.userAgent.includes("Prerender");
};

export default function usePrerender() {
  const [isPrerender, setIsPrerender] = useState(false);

  useEffect(() => {
    if (checkIsPrerender()) {
      setIsPrerender(true);
    } else {
      setIsPrerender(false);
    }
  }, [setIsPrerender]);

  return { isPrerender };
}
