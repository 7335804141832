import { Box, BoxProps, Divider, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

const OrTextContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  padding-left: 15px;
  padding-right: 15px;
  background: var(--neutral-0);
`;

interface OrDividerProps extends BoxProps {
  textContainerProps?: BoxProps;
}
const OrDivider = ({
  textContainerProps = {},
  ...containerProps
}: OrDividerProps) => {
  return (
    <Box
      position="relative"
      height="30px"
      display="flex"
      alignItems="center"
      {...containerProps}
    >
      <OrTextContainer {...textContainerProps}>
        <Text
          fontSize={["18px", "18px", "16px"]}
          lineHeight={["25px", "25px", "23px"]}
          fontWeight={500}
          color="neutral.500"
        >
          or
        </Text>
      </OrTextContainer>
      <Divider color="neutral.200" />
    </Box>
  );
};

export default OrDivider;
