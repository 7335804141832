import { Analytics, getAnalytics } from "firebase/analytics";
import firebaseApp from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { checkIsPrerender } from "hooks/usePrerender";

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: checkIsPrerender()
    ? undefined
    : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebaseApp.initializeApp(config);

export const firebase = firebaseApp;
export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();

firestore.settings({
  experimentalAutoDetectLongPolling: true,
});

export const analytics = checkIsPrerender()
  ? ({} as Analytics)
  : getAnalytics();
export const usersStorageBucket = firebase
  .app()
  .storage(process.env.REACT_APP_FIREBASE_USERS_STORAGE_BUCKET);
