import { IconButton, IconButtonProps } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../styles/icons/close-black-icon.svg";

interface Props extends Partial<IconButtonProps> {
  onClick: () => void;
}

const ModalCloseButton = ({ onClick, ...iconButtonProps }: Props) => {
  return (
    <IconButton
      icon={<CloseIcon />}
      rounded={8}
      padding="0"
      minWidth="30px"
      width="30px"
      height="30px"
      outline="none"
      border="none"
      aria-label="Close modal"
      position="absolute"
      right="20px"
      top="20px"
      bg={["none", "groups.light.300"]}
      transition="background 0.3s"
      onClick={onClick}
      _hover={{ bg: "groups.light.400" }}
      _focus={{ outline: "none" }}
      {...iconButtonProps}
    />
  );
};

export default ModalCloseButton;
