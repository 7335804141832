import { ShareItemType } from './types';

export const fieldNameMapper: Record<ShareItemType, string> = {
  EVENT: 'event_id',
  GROUP: 'group_id',
  LIBRARY_ITEMS: 'library_item_id',
  SHOP_PRODUCT: 'shop_product_id',
  PUBLISHER: 'publisher_id',
  PLAYLIST: 'playlist_id'
};

const getFieldNameBasedOnItemType = (itemType: ShareItemType) => {
  return fieldNameMapper[itemType];
};

export default getFieldNameBasedOnItemType;