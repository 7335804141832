import { useFirebaseEventTracking } from "hooks/useFirebaseEventTracking";
import { useCallback } from "react";
import getFieldNameBasedOnItemType from "./getFieldNameBasedOnItemType";
import { ShareChannelType, ShareItemType } from "./types";

interface ShareContentTrackingParams {
  deep_link_type: "branch";
  share_link_id?: string;
  share_channel: ShareChannelType;
  invite_link: string;
  item_id: string;
  tracking_object_type: ShareItemType;
  share_created_date?: number;
  sharer_user_id?: string;
}

const useShareContentEventTracking = () => {
  const { trackEvent } = useFirebaseEventTracking();

  const shareContentTracking = useCallback(
    (params: ShareContentTrackingParams) => {
      const {
        item_id,
        invite_link,
        deep_link_type = "branch",
        share_channel,
        share_created_date,
        share_link_id,
        sharer_user_id,
        tracking_object_type,
      } = params;
      const itemIdFieldName =
        getFieldNameBasedOnItemType(tracking_object_type) ?? "item_id";

      trackEvent("service_event", undefined, {
        service_event_name: "share_completed",
        deep_link_type,
        share_link_id,
        share_channel,
        invite_link,
        [itemIdFieldName]: item_id,
        tracking_object_type,
        share_created_date,
        sharer_user_id,
      });
    },
    [trackEvent]
  );

  return {
    shareContentTracking,
  };
};

export default useShareContentEventTracking;
