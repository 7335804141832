import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array/includes";
import "core-js/features/array/is-array";
import "core-js/features/array/reduce";
// NOTE: SAVE CHANGES WITHOUT FORMATTING AS ORDER OF IMPORTS IS IMPORTANT
// vscode users: CMD+SHIFT+P, then select 'File: Save without Formatting'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
