/**
 * Used to get first name and last name from given name.
 */
const getFirstAndLastName = (name: string) => {
  const [firstName, lastName] = name?.trim().split(" ") ?? [];

  return { firstName: firstName ?? "", lastName: lastName ?? "" };
};

export default getFirstAndLastName;
