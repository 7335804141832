import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import MetaTags from "../components/Events/MetaTags";
import BrowserLink from "../components/shared/Links/BrowserLink";
import ChromeLogo from "../styles/icons/browser-logo-chrome.svg";
import EdgeLogo from "../styles/icons/browser-logo-edge.svg";
import FirefoxLogo from "../styles/icons/browser-logo-firefox.svg";
import { ReactComponent as InsightTimerLogo } from "../styles/images/insighttimer-white.svg";

const backgroundPath = `${process.env.PUBLIC_URL}/images/background-tent.jpg`;

const PleaseUpgradeBrowserPage = () => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      background={`url(${backgroundPath}) #060606`}
      backgroundPosition="center"
      backgroundSize="cover"
      width="100%"
      height="100%"
      textAlign="center"
    >
      <MetaTags title="Please upgrade your browser | Insight Timer" />
      <Box display="inline-block" mx="auto" p={8}>
        <InsightTimerLogo width="130px" preserveAspectRatio="xMidYMin" />
      </Box>
      <Box
        display="table"
        textAlign="center"
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        minHeight="550px"
      >
        <Box color="white" display="table-cell" verticalAlign="middle" px={8}>
          <Box m="auto" maxWidth="720px">
            <Heading
              display="inline-block"
              fontSize={["23px", "23px", "23px", "29px"]}
            >
              Please upgrade your browser
            </Heading>
            <Text py={4}>
              Here at Insight Timer, we work hard to be the #1 free app for
              sleep, anxiety and stress. We want to provide the best possible
              experiences for our amazing community of users, and we use some of
              the latest technology to do so. Unfortunately, some of our
              features will not work with your current browser.
            </Text>
            <Text paddingTop={4} paddingBottom={6}>
              We recommend upgrading to one of the following browsers:
            </Text>
            <BrowserLink
              title="Google Chrome"
              href="https://www.google.com/chrome/"
              icon={ChromeLogo}
            />
            <BrowserLink
              title="Mozilla Firefox"
              href="https://www.mozilla.org/firefox/download/"
              icon={FirefoxLogo}
            />
            <BrowserLink
              title="Microsoft Edge"
              href="https://www.microsoft.com/en-us/edge"
              icon={EdgeLogo}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PleaseUpgradeBrowserPage;
