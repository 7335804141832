import { isProduction, isWebapp } from "./environment";

const deleteCookie = (name: string) => {
  let domain = "";
  const isInsightTimerHost =
    window.location.origin.includes(".insighttimer.com"); // This for live-staging link

  if (isProduction || isWebapp || isInsightTimerHost) {
    domain = "Domain=.insighttimer.com;";
  }

  document.cookie = `${name}=; ${domain} Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export default deleteCookie;
