import { Box, BoxProps, Text } from "@chakra-ui/react";
import React, { ReactElement, useMemo } from "react";

interface RenderControllerErrorFnParams {
  error: string;
  index: number;
}

export interface FormControlErrorMessageProps extends BoxProps {
  errorMessages?: string[] | string;
  renderControllerError?: (
    fnParams: RenderControllerErrorFnParams
  ) => ReactElement;
}

const FormControlErrorMessage = ({
  errorMessages,
  renderControllerError,
  ...rootContainerProps
}: FormControlErrorMessageProps) => {
  const numberOfErrors = errorMessages?.length ?? 0;
  const errors = useMemo(() => {
    if (Array.isArray(errorMessages)) {
      return errorMessages;
    }

    if (typeof errorMessages === "string") {
      return [errorMessages];
    }

    return [];
  }, [errorMessages]);

  if (!errors.length) {
    return null;
  }

  return (
    <Box {...rootContainerProps}>
      {errors.map((error, index) => {
        return (
          <Text
            fontFamily="Brandon"
            key={error}
            color="red.400"
            fontSize="14px"
            lineHeight="19px"
            fontWeight={500}
            marginBottom={index !== numberOfErrors - 1 ? "2px" : undefined}
          >
            {renderControllerError
              ? renderControllerError({ error, index })
              : error}
          </Text>
        );
      })}
    </Box>
  );
};
export default FormControlErrorMessage;
