import axios from "axios";
import { WEB_FUNNEL_PROD_URL } from "constants/sharedConstants";
import firebase from "firebase/compat/app";
import deleteCookie from "./deleteCookie";
import { auth } from "./firebase";
import getCookie from "./getCookie";
import { setCookie } from "./setCookie";
import { setUserPrivateSettings } from "./userRepository";

interface SignupWithEmailAndPasswordParams {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  tosChecked: boolean; // Terms and condition checked
}

export const signupWithEmailAndPassword = async ({
  email,
  firstName,
  lastName,
  password,
  tosChecked, // Terms and condition checked
}: SignupWithEmailAndPasswordParams) => {
  const fName = firstName.trim();
  const lName = lastName.trim();
  const name = `${fName} ${lName}`;

  if (name.length < 3) {
    throw new Error("The name must be minimum 3 characters.");
  }

  try {
    return await auth
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        if (user) {
          await setUserPrivateSettings({
            uid: user.uid,
            name,
            firstName: fName,
            lastName: lName,
            email,
            tosChecked,
          });
        }
        return user;
      });
  } catch (e: any) {
    // TODO: Update type from 'any'
    switch (e.code) {
      case "auth/invalid-email":
        throw new Error("Email address is not valid");
      case "auth/weak-password":
        throw new Error("The password must be 6 characters long or more.");
      case "auth/email-already-in-use":
        throw new Error("Email address is taken");
      default:
        throw new Error("Unable to signup. Please try again");
    }
  }
};

export const loginWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    return await auth.signInWithEmailAndPassword(email, password);
  } catch (e: any) {
    // TODO: Update type from 'any'
    switch (e.code) {
      case "auth/wrong-password":
        throw new Error(
          "Unable to login. Please check your password and try again"
        );
      case "auth/invalid-email":
        throw new Error(
          "Your email address format is incorrect. Please check it and try again"
        );
      case "auth/user-not-found":
        throw new Error(
          "Your email address is not recognized. Please try again"
        );
      default:
        throw new Error("Unable to login. Please try again");
    }
  }
};

export async function signInWithFacebook() {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  facebookProvider.addScope("public_profile,email");
  facebookProvider.setCustomParameters({
    display: "popup",
  });
  auth.useDeviceLanguage();
  return auth
    .signInWithPopup(facebookProvider)
    .then(async (result) => {
      if (result.user) return result;

      throw new Error("user is null");
    })
    .catch((error) => {
      throw error.message;
    });
}

export async function signInWithApple() {
  const appleProvider = new firebase.auth.OAuthProvider("apple.com");
  appleProvider.addScope("email");
  appleProvider.addScope("name");
  appleProvider.setCustomParameters({
    display: "popup",
  });
  auth.useDeviceLanguage();
  return auth
    .signInWithPopup(appleProvider)
    .then(async (result) => {
      if (result.user) return result;
      throw new Error("user is null");
    })
    .catch((error) => {
      throw error.message;
    });
}

export function signInWithGoogle() {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  auth.useDeviceLanguage();
  return auth
    .signInWithPopup(googleProvider)
    .then((result) => {
      if (result.user) return result;
      throw new Error("user is null");
    })
    .catch((error) => {
      throw error.message;
    });
}

/**
 * Use perform login via old session flow(firebase function)
 **/
const signInWithOldSessionCookie = () => {
  return axios
    .post(`${WEB_FUNNEL_PROD_URL}/auth/session_verify`, undefined, {
      withCredentials: true,
    })
    .then((resp) => auth.signInWithCustomToken(resp?.data))
    .then((result) => {
      if (result?.user) {
        return result;
      }

      throw new Error("user is null");
    });
};

/**
 * Use perform login via new session flow
 **/
const signInWithNewSessionCookie = () => {
  return axios
    .post(
      `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_verify`,
      undefined,
      {
        withCredentials: true,
      }
    )
    .then((resp) => auth.signInWithCustomToken(resp?.data))
    .then((result) => {
      if (result?.user) {
        return result;
      }

      throw new Error("user is null");
    });
};

/**
 * SSO if already logged in from main website
 */
export async function signInWithSessionCookie() {
  const oldSessionCookie = getCookie("__session");
  const newSessionCookie = getCookie("hasSessionCookie");

  if (oldSessionCookie) {
    return signInWithOldSessionCookie();
  }

  if (newSessionCookie) {
    return signInWithNewSessionCookie();
  }

  throw new Error("SESSION_COOKIE_NOT_AVAILABLE");
}

/**
 * Used to remove session cookie from browser.
 **/
const removeLocalSessionCookies = () => {
  deleteCookie("__session"); // In old flow, We have __session cookie store in browser. clear it on logout.
  deleteCookie("hasSessionCookie"); // In new flow, We have hasSessionCookie stored in browser. clear it on logout.
};

/**
 * Used to remove session cookie from server.
 **/
const removeRemoteSessionCookies = () => {
  const oldSessionCookie = getCookie("__session");

  if (oldSessionCookie) {
    return axios({
      method: "post",
      url: `https://insighttimer.com/auth/session_logout`,
      withCredentials: true,
    });
  }

  return axios.post(
    `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_logout`
  );
};

/**
 * Used to remove session cookie from browser and server.
 **/
export const removeSessionCookies = () => {
  removeLocalSessionCookies();

  return removeRemoteSessionCookies();
};

export async function getBearerToken(user?: firebase.User | null) {
  return user?.getIdToken();
}

export async function getBearerTokenForCurrentUser() {
  return auth.currentUser?.getIdToken();
}

export const logout = async () => {
  await removeSessionCookies();
  return auth.signOut();
};

export async function getImpersonationToken(userId: string) {
  const bearerToken = await auth.currentUser?.getIdToken();

  return (
    axios
      .get<string>(
        `
  https://users-api.insighttimer-api.net/api/v1/_admin/users/${userId}/token`,
        { headers: { Authorization: `Bearer ${bearerToken}` } }
      )
      .then(({ data }) => data)
      // If the request failed e.g. user is not an admin, just return the authed user's token
      .catch(() => {
        return bearerToken;
      })
  );
}

export interface SessionLoginParams {
  redirectUrl?: string;
}

/**
 * Used to perform session login. Invoke it before redirect user to other insight timer based url.
 **/
export async function sessionLoginViaNewApi(params?: SessionLoginParams) {
  return auth.currentUser
    ?.getIdToken()
    .then((token) => {
      const url = `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_login`;

      return axios.post(
        url,
        {
          idToken: token,
        },
        {
          withCredentials: true,
        }
      );
    })
    .then((res) => {
      setCookie({ key: "hasSessionCookie", value: "true" });
      if (params?.redirectUrl) {
        window.location.replace(params.redirectUrl);
        return;
      }
      return res;
    });
}

export interface SessionLoginParams {
  redirectUrl?: string;
}

/**
 * Used to perform session login. Invoke it before redirect user to other insight timer based url.
 **/
export async function sessionLogin(params?: SessionLoginParams) {
  return auth.currentUser
    ?.getIdToken()
    .then((token) =>
      axios.post(
        `${WEB_FUNNEL_PROD_URL}/auth/session_login`,
        {
          id_token: token,
        },
        {
          withCredentials: true,
        }
      )
    )
    .then((res) => {
      if (res && params?.redirectUrl) {
        window.location.replace(params.redirectUrl);
        return;
      }

      return res;
    });
}
