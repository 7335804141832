import React from "react";
import AuthSignUpView from "./AuthSignUpView";
import { VIEWS } from "./commonTypes";

interface AuthSignUpOptionsViewProps {
  setAuthView: (view: VIEWS) => void;
  hideSignUpWithAppleBtn?: boolean;
}

const AuthSignUpOptionsView = ({
  setAuthView,
  hideSignUpWithAppleBtn,
}: AuthSignUpOptionsViewProps) => {
  return (
    <AuthSignUpView
      showSignUpOptionsOnly
      showSignUpWithEmailOption
      autofocusSignupWithAppleButton
      hideSignUpWithAppleBtn={hideSignUpWithAppleBtn}
      setAuthView={setAuthView}
    />
  );
};

export default AuthSignUpOptionsView;
