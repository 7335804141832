import React, { forwardRef } from "react";
import SharedInput, { SharedInputProps } from "../FormControls/SharedInput";

const CommonAuthInput = forwardRef<HTMLInputElement, SharedInputProps>(
  (props, ref) => {
    return (
      <SharedInput
        inputLabelProps={{
          fontSize: ["16px", "14px", "14px"],
          lineHeight: ["23px", "19px", "19px"],
          fontWeight: 500,
          color: "neutral.600",
        }}
        background="neutral.100"
        height={["44px", "44px", "48px"]}
        _focus={{
          outline: "2px solid var(--chakra-colors-teal-500)",
          backgroundColor: "white",
        }}
        ref={ref}
        {...props}
      />
    );
  }
);

export default CommonAuthInput;
