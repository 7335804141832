import {
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { AuthState, useFirebaseAuth } from "hooks/useFirebaseAuth";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ModalCloseButton from "../Modals/ModalCloseButton";
import SharedModal from "../Modals/SharedModal";
import AuthModalViews from "./AuthModalViews";
import { VIEWS } from "./commonTypes";

export interface ModalCloseCallbackFnTypeParams {
  ignoreResetSuccessCallback: boolean;
}

export type ModalCloseCallbackFnType = (
  params: ModalCloseCallbackFnTypeParams
) => void;

export interface AuthModalMetaRefSchema {
  modalCloseCallback?: ModalCloseCallbackFnType;
}
export interface OpenModalParams {
  email?: string;
}

export interface RefDetailsSchema {
  setView: (view: VIEWS) => void;
  isOpen: boolean;
  openModal: (params?: OpenModalParams) => void;
  closeModal: ModalCloseCallbackFnType;
}

export interface Props {
  onAuthenticated?: () => void;
  hideSignUpWithAppleBtn?: boolean;
  modalDetailsRef: React.MutableRefObject<RefDetailsSchema | undefined>;
  onCloseCallback?: ModalCloseCallbackFnType;
  avoidDialogClose?: boolean;
}

const AuthModal: React.FC<Props> = ({
  onAuthenticated,
  hideSignUpWithAppleBtn,
  modalDetailsRef,
  onCloseCallback,
  avoidDialogClose,
}) => {
  const [startScreen, setScreen] = useState<VIEWS>("LOGIN");
  const [prefillEmail, setPrefillEmail] = useState<string>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { authState } = useFirebaseAuth();
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });
  const authModalMetaRef = useRef<AuthModalMetaRefSchema>({
    modalCloseCallback: undefined,
  });
  authModalMetaRef.current.modalCloseCallback = onCloseCallback;

  const onAuthModalClose = useCallback(
    ({ ignoreResetSuccessCallback }: ModalCloseCallbackFnTypeParams) => {
      onClose();

      if (authModalMetaRef.current.modalCloseCallback) {
        authModalMetaRef.current.modalCloseCallback({
          ignoreResetSuccessCallback,
        });
      }

      setPrefillEmail(undefined);
    },
    [onClose]
  );

  useEffect(() => {
    const onAuthSuccess = () => {
      if (isOpen) {
        if (onAuthenticated) {
          onAuthenticated();
        }

        onAuthModalClose({ ignoreResetSuccessCallback: true });
      }
    };

    window.addEventListener("Authenticated", onAuthSuccess);

    return () => {
      window.removeEventListener("Authenticated", onAuthSuccess);
    };
  }, [isOpen, onAuthModalClose, onAuthenticated]);

  const setAuthView = useCallback((view: VIEWS) => {
    if (setScreen) {
      setScreen(view);
    }
  }, []);

  const openAuthModal = useCallback(
    (params?: OpenModalParams) => {
      if (authState === AuthState.AUTHORIZED) {
        return;
      }

      if (params?.email) {
        setPrefillEmail(params?.email);
      }

      onOpen();
    },
    [onOpen, authState]
  );

  useImperativeHandle(modalDetailsRef, () => {
    return {
      setView: setAuthView,
      isOpen,
      openModal: openAuthModal,
      closeModal: onAuthModalClose,
    };
  });

  return (
    <SharedModal
      isOpen={isOpen}
      onClose={() => {
        onAuthModalClose({ ignoreResetSuccessCallback: false });
      }}
      size={isMobile ? "full" : "lg"}
      preserveScrollBarGap
      closeOnOverlayClick={avoidDialogClose ? false : true}
      closeOnEsc={avoidDialogClose ? false : true}
      isCentered={false}
    >
      <ModalOverlay />
      <ModalContent
        padding={[
          "124px 25px 86px 25px",
          "68px 90px 86px 90px",
          "68px 90px 86px 90px",
        ]}
        borderRadius={["0px", "18px", "18px"]}
        marginTop={["0px", "42px", "42px"]}
        marginBottom={["0px", "42px", "42px"]}
        boxShadow="0px 0px 16px rgba(0, 0, 0, 0.06)"
      >
        {!avoidDialogClose ? (
          <ModalCloseButton
            top={["25px", "24px", "24px"]}
            right={["36px", "22px", "22px"]}
            onClick={() => {
              onAuthModalClose({ ignoreResetSuccessCallback: false });
            }}
          />
        ) : null}

        <ModalBody padding="0px" maxWidth="400px" width="100%">
          {startScreen ? (
            <AuthModalViews
              view={startScreen}
              setAuthView={setAuthView}
              hideSignUpWithAppleBtn={hideSignUpWithAppleBtn}
              prefillEmail={prefillEmail}
            />
          ) : null}
        </ModalBody>
      </ModalContent>
    </SharedModal>
  );
};

export default AuthModal;
