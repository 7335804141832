import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import styled from "@emotion/styled";
import useMutationObserver from "@rooks/use-mutation-observer";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { ITourStep } from "../../../lib/tours";
import theme from "../../../theme";

const Arrow = styled.div`
  & {
    position: absolute;
    width: 24px;
    height: 24px;
  }

  &::before {
    color: ${theme.colors.maroon};
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  [data-tour-card][data-popper-placement^="top"] > & {
    bottom: -28px;

    &::before {
      content: "▼";
    }
  }

  [data-tour-card][data-popper-placement^="bottom"] > & {
    top: -28px;

    &::before {
      content: "▲";
    }
  }

  [data-tour-card][data-popper-placement^="left"] > & {
    right: -28px;

    &::before {
      content: "►";
    }
  }

  [data-tour-card][data-popper-placement^="right"] > & {
    left: -28px;

    &::before {
      content: "◄";
    }
  }
`;

interface IProps {
  refElement: Element;
  step: ITourStep;
  canProceed?: boolean;
  canEndTour?: boolean;
  nextStep: () => void;
  endTour: () => void;
}

const TourStep: React.FC<IProps> = ({
  refElement,
  step,
  canProceed = false,
  canEndTour = false,
  nextStep,
  endTour,
}) => {
  const docRef = useRef(document.body);
  const [popoverEl, setPopoverEl] = useState<HTMLElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(refElement, popoverEl, {
    modifiers: [
      { name: "arrow", options: { element: arrowEl } },
      {
        name: "preventOverflow",
        options: { tether: false, altAxis: true, padding: 24 },
      },
      { name: "offset", options: { offset: [0, 24] } },
    ],
  });

  // Update popper position on DOM updates
  useMutationObserver(docRef, () => {
    update?.();
  });

  // Update popper position on step changes
  useEffect(() => {
    update?.();
  }, [update, step]);

  // Display next if there's no finish button, and there's either no validator
  // or there is and the next button has been explicitly enabled.
  const hasNextButton = !canEndTour && (!step.validator || step.hasNextButton);
  const hasFooter = hasNextButton || canEndTour;

  return (
    <Box
      ref={setPopoverEl}
      px={8}
      pt={4}
      pb={hasFooter ? "80px" : 4}
      shadow="lg"
      backgroundColor="white"
      borderRadius={8}
      border={`${theme.colors.maroon} 4px solid`}
      style={styles.popper}
      {...attributes.popper}
      maxWidth="500px"
      zIndex={theme.zIndices.tooltip + 1}
      data-tour-card
    >
      {step.heading && (
        <Heading size="sm" mb={2}>
          {step.heading}
        </Heading>
      )}
      <Box fontSize="14px">{step.body}</Box>
      <Arrow ref={setArrowEl} style={styles.arrow} />
      {hasFooter && (
        <Flex
          position="absolute"
          justifyContent="flex-end"
          backgroundColor="white"
          boxShadow="0px -2px 6px rgba(50, 50, 50, 0.4)"
          borderRadius="0 0 8px 8px"
          bottom={0}
          left={0}
          right={0}
          p={2}
        >
          {hasNextButton && (
            <Button
              backgroundColor="rgb(34, 34, 34)"
              _hover={canProceed ? { bg: "rgb(56, 56, 56)" } : {}}
              color="white"
              isDisabled={!canProceed}
              onClick={nextStep}
            >
              Next
            </Button>
          )}
          {canEndTour && (
            <Button
              backgroundColor="rgb(34, 34, 34)"
              _hover={{ bg: "rgb(56, 56, 56)" }}
              color="white"
              onClick={endTour}
            >
              End Tour
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default TourStep;
