import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useGlobalStats } from "hooks/useGlobalStats";
import { numberConvert } from "lib/numberHelpers";
import React from "react";

interface AuthSignUpViewHeaderProps {
  onLoginButtonClick: () => void;
}

const AuthSignUpViewHeader = ({
  onLoginButtonClick,
}: AuthSignUpViewHeaderProps) => {
  const { globalStats, isGlobalStatsLoading } = useGlobalStats();
  const numberOfJoinedUsers = numberConvert(
    globalStats?.total_users ?? 0,
    true,
    0
  );

  return (
    <Box>
      <Heading
        as="h2"
        fontFamily="Brandon"
        fontSize="28px"
        lineHeight="34px"
        fontWeight={500}
        color="neutral.700"
        marginBottom={["20px", "22px", "22px"]}
      >
        Create a free account and join{" "}
        {!isGlobalStatsLoading ? numberOfJoinedUsers : null} people today.
      </Heading>
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        textAlign="center"
        marginBottom="35px"
      >
        <Text
          fontFamily="Brandon"
          fontSize="16px"
          lineHeight="22px"
          fontWeight={400}
          color="neutral.600"
        >
          Already have an account?
        </Text>
        <Button variant="unstyled" marginLeft="5px" height="auto">
          <Text
            fontFamily="Brandon"
            fontSize="16px"
            lineHeight="22px"
            fontWeight={500}
            color="teal.500"
            onClick={() => {
              onLoginButtonClick();
            }}
          >
            Log in
          </Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default AuthSignUpViewHeader;
