import { Checkbox, CheckboxProps, Link, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

type TOSCheckboxProps = CheckboxProps;

const StyledTOSCheckbox = styled(Checkbox)`
  & .chakra-checkbox__control,
  &:hover .chakra-checkbox__control {
    align-self: flex-start;
    background-color: transparent;
    color: teal;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    margin-right: 9px;
    margin-top: 2px;
  }

  & .chakra-checkbox__control[data-focus] {
    outline: 2px solid var(--chekra-colors-brandTeal-500);
    box-shadow: none;
  }

  & input:checked + .chakra-checkbox__control {
    background-color: transparent;
    color: teal;
  }
`;

const TOSCheckbox = (props: TOSCheckboxProps) => {
  return (
    <StyledTOSCheckbox {...props}>
      <Text
        fontFamily="Brandon"
        fontSize="14px"
        lineHeight="21px"
        fontWeight={400}
        color="var(--neutral-600)"
        maxWidth={["286px", "100%"]}
        textAlign="start"
      >
        By checking this box, I agree to Insight Timer&apos;s{" "}
        <Link
          href="https://insighttimer.com/legal/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          color="brandTeal.500"
          whiteSpace="nowrap"
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          href="https://insighttimer.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          color="brandTeal.500"
        >
          Privacy Policy
        </Link>
      </Text>
    </StyledTOSCheckbox>
  );
};

export default TOSCheckbox;
