import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";

interface IBrowserLinkProps {
  icon: string; // TODO: can we improve this type?
  title: string;
  href: string;
}

const BrowserLink: React.FC<IBrowserLinkProps> = ({ icon, title, href }) => {
  return (
    <Box display="inline-block" mx={4}>
      <Link href={href} target="_blank">
        <img src={icon} alt={title} width="60px" />
        <Text
          fontSize="14px"
          fontWeight={700}
          width="60px"
          textAlign="center"
          paddingTop={2}
        >
          {title}
        </Text>
      </Link>
    </Box>
  );
};

export default BrowserLink;
