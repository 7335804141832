import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import tourSteps, {
  createStore,
  getCurrentStore,
  isValidTourKey,
  ITourStep,
  ITourStore,
  setCurrentStore,
} from "../../../lib/tours";
import useDisableElements from "../../../lib/tours/useDisableElements";
import useHideElements from "../../../lib/tours/useHideElements";
import useTourElement from "../../../lib/tours/useTourElement";
import TourStep from "./TourStep";

enum Action {
  "SET_TOUR_STORE" = "SET_TOUR_STORE",
  "NEXT_STEP" = "NEXT_STEP",
  "SET_CAN_PROCEED" = "SET_CAN_PROCEED",
}
type ReducerAction =
  | { type: Action.SET_TOUR_STORE; store: ITourStore }
  | { type: Action.NEXT_STEP }
  | { type: Action.SET_CAN_PROCEED; canProceed: boolean };

interface IReducerState extends ITourStore {
  canProceed: boolean;
}

const syncedReducer = (
  state: IReducerState,
  action: ReducerAction
): IReducerState => {
  const prevState = clone(state);
  const newState = (() => {
    switch (action.type) {
      case Action.SET_TOUR_STORE:
        return { ...state, ...action.store };
      case Action.NEXT_STEP: {
        const nextIndex = state.currentIndex + 1;
        const hasNextStep = state.steps.length > nextIndex;

        return hasNextStep && isValidTourKey(state.activeTour)
          ? {
              ...state,
              currentIndex: nextIndex,
              resumeIndex:
                tourSteps[state.activeTour][nextIndex].resumeIndex || nextIndex,
            }
          : state;
      }
      case Action.SET_CAN_PROCEED:
        return { ...state, canProceed: action.canProceed };
      default:
        return state;
    }
  })();
  // Do nothing if states are unchanged
  if (newState === state || isEqual(newState, prevState)) {
    return state;
  }

  // if store has changed, update local storage
  const { canProceed, ...oldStore } = state;
  const { canProceed: canProceedNew, ...newStore } = newState;
  if (!isEqual(oldStore, newStore)) {
    setCurrentStore(newStore);
  }

  return newState;
};

const defaultState: IReducerState = {
  activeTour: "",
  steps: [],
  currentIndex: 0,
  resumeIndex: 0,
  tourData: {},
  canProceed: false,
};

const getCurrentStep = (state: IReducerState): ITourStep | null => {
  if (!state.activeTour || !isValidTourKey(state.activeTour)) {
    return null;
  }

  return tourSteps[state.activeTour][state.currentIndex];
};

const TourManager = () => {
  const [state, dispatch] = useReducer(
    syncedReducer,
    defaultState,
    (arg: IReducerState) => {
      const oldStore = getCurrentStore();
      // Tour is in progress
      if (oldStore && isValidTourKey(oldStore?.activeTour)) {
        // If we are resuming from a different step, overwrite existing store
        const initialStore =
          oldStore.currentIndex === oldStore.resumeIndex
            ? oldStore
            : createStore(
                oldStore.activeTour,
                oldStore.resumeIndex,
                oldStore.tourData
              );
        setCurrentStore(initialStore);
        return { ...initialStore, canProceed: false };
      }
      return arg;
    }
  );
  const location = useLocation();
  const currentStep = getCurrentStep(state);

  useEffect(() => {
    const oldStore = getCurrentStore();
    if (oldStore) {
      dispatch({ type: Action.SET_TOUR_STORE, store: oldStore });
    }
  }, [location.pathname]);

  const setData = useCallback(
    (data: any) =>
      dispatch({
        type: Action.SET_TOUR_STORE,
        store: { ...state, tourData: { ...state.tourData, ...data } },
      }),
    [state]
  );

  const stepClickHandler = currentStep?.onClick;

  const tourElement = useTourElement(
    `${state.activeTour}/${currentStep?.selector}`,
    stepClickHandler
      ? {
          onClick: (e) => {
            stepClickHandler(setData, e);
          },
        }
      : undefined
  );

  const nextStep = useCallback(() => {
    if (
      currentStep?.validator === undefined ||
      currentStep?.validator(state.tourData)
    ) {
      currentStep?.onNext?.(setData);
      dispatch({ type: Action.NEXT_STEP });
    }
  }, [currentStep, state.tourData, setData]);

  const endTour = useCallback(() => {
    dispatch({ type: Action.SET_TOUR_STORE, store: createStore("", 0) });
  }, []);

  // Whenever tour-store updates, check if 'next' transition is valid.
  useEffect(() => {
    if (currentStep?.validator === undefined) {
      dispatch({ type: Action.SET_CAN_PROCEED, canProceed: true });
      return;
    }

    if (currentStep.hasNextButton) {
      dispatch({
        type: Action.SET_CAN_PROCEED,
        canProceed: currentStep?.validator(state.tourData),
      });
      return;
    }

    nextStep();
  }, [state, currentStep, nextStep]);

  // Disable or hide any elements specified in step config
  useDisableElements(currentStep?.disabledElements);
  useHideElements(currentStep?.hiddenElements);

  // Don't render step if tour not active, or if tour element is not in DOM.
  const currentStepRoute =
    typeof currentStep?.route === "string"
      ? currentStep.route
      : currentStep?.route(state.tourData);
  if (!currentStep || location.pathname !== currentStepRoute || !tourElement) {
    return null;
  }

  const canEndTour = state.steps.length === state.currentIndex + 1;

  return (
    <TourStep
      refElement={tourElement}
      step={currentStep}
      canProceed={state.canProceed}
      canEndTour={canEndTour}
      nextStep={nextStep}
      endTour={endTour}
    />
  );
};

export default TourManager;
