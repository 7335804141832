export enum PageTypes {
  LiveStream = "live_stream_page",
  GroupMeditationPage = "group_meditation_page",
  GroupPage = "group_page",
  GroupsHomePage = "groups_home_page",
  PageNotFound = "page_not_found",
  Unknown = "unknown_page",
  OrganisationHome = "atwork_dashboard_home_page",
  OrganisationBillingDashboard = "atwork_dashboard_billing_page",
  OrganisationMembersDashboard = "atwork_dashboard_members_page",
  OrganisationStatsDashboard = "atwork_dashboard_stats_page",
  AtWorkSubscribe = "atwork_txnflow_subscribe_page",
  ReferralDownloadAppPage = "referral_download_app_page",
}
