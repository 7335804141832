// https://github.com/Insight-Timer/web-funnel/blob/78adbc6519141a185633e63931884fbe30bd8088/src/App.js#L102

import { checkIsPrerender } from "hooks/usePrerender";

export const initFacebookSdk = () => {
  if(checkIsPrerender()) {
    return;
  }

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "157891377582896",
      cookie: true,
      xfbml: true,
      version: "v5.0",
    });

    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.async = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  console.log();
};
