import { Spinner as ChakraSpinner } from "@chakra-ui/react";
import React from "react";

interface Props {
  color?: string;
  emptyColor?: string;
  size?: "md" | "sm";
}

const Spinner = ({
  color = "work.neutral.700",
  emptyColor = "work.neutral.000",
  size = "md",
}: Props) => (
  <ChakraSpinner
    speed="1.0s"
    color={color}
    emptyColor={emptyColor}
    size={size}
    position="absolute"
    left={0}
    right={0}
    mx="auto"
    textAlign="center"
  />
);

export default Spinner;
