import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ReactComponent as HidePasswordIcon } from "styles/icons/hide-password-icon.svg";
import { ReactComponent as ShowPasswordIcon } from "styles/icons/show-password-icon.svg";

export interface OnShowHideButtonClickParams {
  show: boolean;
}

interface PasswordShowHideButtonProps extends BoxProps {
  onShowHideButtonClick: ({ show }: OnShowHideButtonClickParams) => void;
}
const PasswordShowHideButton = ({
  onShowHideButtonClick,
  ...containerProps
}: PasswordShowHideButtonProps) => {
  const [displayShowButton, setDisplayShowButton] = useState(true);
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });
  const showOrHideIcon = displayShowButton ? (
    <ShowPasswordIcon
      fill="transparent"
      stroke="var(--neutral-600)"
      width="20px"
      height="15px"
      title="Show password"
    />
  ) : (
    <HidePasswordIcon
      fill="transparent"
      stroke="var(--neutral-600)"
      width="20px"
      height="15px"
      title="Hide password"
    />
  );
  const handleShowHideButtonClick = () => {
    const showPassword = displayShowButton;

    setDisplayShowButton((previousShowButton) => !previousShowButton);
    onShowHideButtonClick({ show: showPassword });
  };

  return (
    <Box
      position="absolute"
      right="12px"
      top="50%"
      transform="translateY(-50%)"
      display="flex"
      alignItems="center"
      {...containerProps}
    >
      {isMobile ? (
        <IconButton
          tabIndex={-1}
          icon={showOrHideIcon}
          variant="unstyled"
          aria-label="show or hide password"
          width="40px"
          height="16x"
          display="flex"
          _focus={{
            outline: "none",
          }}
          css={`
            svg {
              stroke: var(--chakra-colors-teal-500);
            }
          `}
          onClick={handleShowHideButtonClick}
        />
      ) : (
        <Button
          tabIndex={-1}
          variant="unstyled"
          aria-label="show or hide password"
          height="20px"
          _focus={{
            outline: "none",
          }}
          onClick={handleShowHideButtonClick}
        >
          <Text
            fontSize="14px"
            lineHeight="19px"
            fontWeight={500}
            color="teal.500"
          >
            {displayShowButton ? "Show" : "Hide"}
          </Text>
        </Button>
      )}
    </Box>
  );
};

export default PasswordShowHideButton;
