import { Box, BoxProps } from "@chakra-ui/react";
import { useSessionAuth } from "hooks/useSessionAuth";
import React from "react";
import Spinner from "../Spinners/Spinner";
import AuthLoginView from "./AuthLoginView";
import AuthSignUpEmailView from "./AuthSignUpEmailView";
import AuthSignUpOptionsView from "./AuthSignUpOptionsView";
import AuthSignUpView from "./AuthSignUpView";
import { VIEWS } from "./commonTypes";

interface AuthModalViewsProps extends BoxProps {
  view: VIEWS;
  hideSignUpWithAppleBtn?: boolean;
  prefillEmail?: string;
  setAuthView: (view: VIEWS) => void;
}

const AuthModalViews = ({
  view,
  prefillEmail,
  hideSignUpWithAppleBtn,
  setAuthView,
}: AuthModalViewsProps) => {
  const { isCheckingSessionCookie } = useSessionAuth();

  if (isCheckingSessionCookie) return <Spinner />;

  return (
    <Box>
      {view === "LOGIN" && <AuthLoginView setAuthView={setAuthView} />}
      {view === "SIGN_UP" && (
        <AuthSignUpView
          setAuthView={setAuthView}
          hideSignUpWithAppleBtn={hideSignUpWithAppleBtn}
          prefillEmail={prefillEmail}
        />
      )}
      {view === "SIGN_UP_OPTIONS_VIEW" && (
        <AuthSignUpOptionsView
          hideSignUpWithAppleBtn={hideSignUpWithAppleBtn}
          setAuthView={setAuthView}
        />
      )}
      {view === "SIGN_UP_EMAIL_VIEW" && (
        <AuthSignUpEmailView
          setAuthView={setAuthView}
          prefillEmail={prefillEmail}
        />
      )}
    </Box>
  );
};

export default AuthModalViews;
