/* eslint-disable no-template-curly-in-string */
import { logEvent, setUserId } from "firebase/analytics";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { isDevelopment, isStaging } from "../lib/environment";
import { analytics, analytics as firebaseAnalytics } from "../lib/firebase";
import {
  FirebaseEvent,
  FirebaseEventAdditionalProps,
  FirebaseEventPageName,
  FirebaseEventProps,
  FirebaseEventType,
} from "../lib/tracking/firebaseEventTracking";
import { AuthState, useFirebaseAuth } from "./useFirebaseAuth";
import { checkIsPrerender } from "./usePrerender";

export interface TrackEventConfig {
  ignorePageName: boolean;
}

interface Context {
  trackEvent: (
    eventType: FirebaseEventType,
    eventName?: FirebaseEvent,
    additionalProps?: FirebaseEventAdditionalProps,
    config?: TrackEventConfig
  ) => void;
  trackClick: (
    eventName: FirebaseEvent,
    additionalProps?: FirebaseEventAdditionalProps,
    config?: TrackEventConfig
  ) => void;
  trackPageView: (
    pageName: FirebaseEventPageName,
    additionalProps?: FirebaseEventAdditionalProps
  ) => void;
  setPreviousPage: (pageName: string) => void;
}

const FirebaseEventTrackingContext = createContext<Context>({
  trackEvent: () => null,
  trackClick: () => null,
  trackPageView: () => null,
  setPreviousPage: () => null,
});

export const FirebaseEventTrackingProvider: React.FC = ({ children }) => {
  const { firebaseUser, authState } = useFirebaseAuth();
  const currentPageName = useRef<string>();
  const previousPageName = useRef<string>();

  const logEventWithFirebase = useCallback(
    (eventName: string, eventData: FirebaseEventProps) => {
      const debugEvents =
        isStaging ||
        isDevelopment ||
        localStorage.getItem("debug_events") === "true";

      if (checkIsPrerender()) {
        return;
      }

      if (debugEvents) {
        console.log(
          `%c Logged Event: ${eventName}`,
          "background: #222; color: #66ccff",
          eventData
        );
      }

      logEvent(firebaseAnalytics, eventName, eventData);
    },
    []
  );

  // TODO: Remove "eventName" prop.
  const trackEvent = useCallback(
    (
      eventType: FirebaseEventType,
      eventName?: FirebaseEvent,
      additionalProps?: FirebaseEventAdditionalProps,
      config?: TrackEventConfig
    ) => {
      const eventData: FirebaseEventProps = {
        event_type: eventType,
        event_name: eventType, // Not a mistake, this should be the same value as event_type.
        user_agent: navigator.userAgent,
        ...additionalProps,
      };

      if (!config?.ignorePageName) {
        eventData.page_name = currentPageName.current;
        eventData.previous_page_name = previousPageName.current;
      }

      logEventWithFirebase(eventType, eventData);
    },
    [logEventWithFirebase, previousPageName]
  );

  useEffect(() => {
    if (checkIsPrerender()) {
      return;
    }

    if (authState === AuthState.AUTHORIZED && firebaseUser) {
      setUserId(analytics, firebaseUser.uid);
    }
  }, [authState, firebaseUser]);

  const trackClick = useCallback(
    (
      clickEventName: FirebaseEvent,
      additionalProps?: FirebaseEventAdditionalProps,
      config?: TrackEventConfig
    ) => {
      trackEvent(
        "click",
        clickEventName,
        {
          click_event_name: clickEventName,
          ...additionalProps,
        },
        config
      );
    },
    [trackEvent]
  );

  const trackPageView = useCallback(
    (
      pageName: FirebaseEventPageName,
      additionalProps?: FirebaseEventAdditionalProps
    ) => {
      currentPageName.current = pageName;
      trackEvent("page_view", FirebaseEvent.PageView, additionalProps);
    },
    [trackEvent]
  );

  const setPreviousPage = useCallback((pageName: string) => {
    previousPageName.current = pageName;
  }, []);

  return (
    <FirebaseEventTrackingContext.Provider
      value={{ trackClick, trackPageView, trackEvent, setPreviousPage }}
    >
      {children}
    </FirebaseEventTrackingContext.Provider>
  );
};

export const useFirebaseEventTracking = () => {
  const context = useContext(FirebaseEventTrackingContext);
  return context;
};
