import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabelProps,
  Input,
  InputProps,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import SharedLabel from "../SharedLabel";

export interface SharedInputProps extends InputProps {
  id: string;
  name: string;
  label?: string;
  helperText?: string;
  isInvalid?: boolean;
  error?: string;
  inputLabelProps?: FormLabelProps;
  inputErrorProps?: FormErrorMessageProps;
  containerProps?: BoxProps;
}

const SharedInput = forwardRef<HTMLInputElement, SharedInputProps>(
  (
    {
      id,
      name,
      label,
      helperText,
      isInvalid,
      error,
      inputLabelProps = {},
      inputErrorProps = {},
      containerProps = {},
      ...inputProps
    },
    ref
  ) => {
    return (
      <FormControl
        as={Box}
        {...containerProps}
        isInvalid={!!(isInvalid && error)}
      >
        {label && (
          <SharedLabel htmlFor={id} marginBottom="5px" {...inputLabelProps}>
            {label}
          </SharedLabel>
        )}
        <Input
          id={id}
          name={name}
          type="text"
          ref={ref}
          isInvalid={isInvalid}
          borderRadius="10px"
          bgColor="neutral.150"
          minHeight="46px"
          padding="11px 10px 12px 14px"
          fontFamily="Brandon"
          fontSize="16px"
          lineHeight="22px"
          fontWeight={500}
          border="none"
          _placeholder={{
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 500,
            color: "work.neutral.500",
          }}
          _focus={{
            outline: "2px solid",
            outlineColor: "work.workplaceBlue.400",
            backgroundColor: "work.neutral.000",
          }}
          _invalid={{
            boxShadow: "0px 0px 0px 1px var(--chakra-colors-red-300)",
          }}
          outlineOffset={0}
          {...inputProps}
        />
        {!isInvalid && helperText ? (
          <FormHelperText fontFamily="Brandon">{helperText}</FormHelperText>
        ) : null}
        {isInvalid && (
          <FormErrorMessage fontFamily="Brandon" {...inputErrorProps}>
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default SharedInput;
