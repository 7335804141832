type eventOptions = {
  [key: string]: any;
};

/**
 * Used to fire custom event on window object.
 * You can listen on given event on window object.
 */
const fireEvent = (eventName: string, config?: eventOptions) => {
  const eventOption = config ?? {};
  const audioLoadedEvent = new CustomEvent(eventName, eventOption);

  window?.dispatchEvent(audioLoadedEvent);
};

export default fireEvent;
