import { DeepLinkData } from "branch-sdk";
import { ShareItemType } from "components/shared/ShareMenu/types";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React, { useContext, useEffect, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import ShareModal from "../components/shared/ShareModal";
import * as Branch from "../lib/branch";
import { useFirebaseAuth } from "./useFirebaseAuth";

interface GeneratedBranchLinkDataSchema extends DeepLinkData {
  data: DeepLinkData["data"] & {
    $share_link_id: string;
    $share_created_date: string;
    $sharer_user_id?: string;
  };
}

interface State {
  isOpen: boolean;
  deepLink?: string;
  webLink?: string;
  branchLink?: string;
  title?: string;
  message?: string;
  ogImageUrl?: string;
  ogDescription?: string;
  fallbackUrl?: string;
  isWebOnly?: boolean;
  additionalQueryString?: string; // Additional query that pass along with share url like "utm_medium(pass type of the content)"
  shareItemId?: string;
  shareItemType?: ShareItemType;
  branchLinkData?: GeneratedBranchLinkDataSchema;
}

type Action =
  | {
      type: "SHOW_POPUP";
      webLink: string;
      deepLink?: string;
      title: string;
      message?: string;
      ogImageUrl?: string;
      ogDescription?: string;
      fallbackUrl?: string; // branch redirect to this url if user don't has insighttimer app on the device. Also, if fallback link is provided, branch fetch og details from fallback url and ignore manually provided og details.
      isWebOnly?: boolean;
      additionalQueryString?: string;
      shareItemId?: string;
      shareItemType?: ShareItemType;
    }
  | {
      type: "HIDE_POPUP";
    };

type InternalAction =
  | { type: "SET_BRANCH_LINK"; value: string }
  | {
      type: "SET_GENERATED_BRANCH_LINK_DATA";
      value: GeneratedBranchLinkDataSchema;
    };

type ReducerAction = Action | InternalAction;

const initialState: State = {
  isOpen: false,
};

const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case "SHOW_POPUP":
      return {
        ...state,
        isOpen: true,
        deepLink: action.deepLink,
        webLink: action.webLink,
        title: action.title,
        message: action.message,
        ogImageUrl: action.ogImageUrl,
        ogDescription: action.ogDescription,
        fallbackUrl: action.fallbackUrl,
        isWebOnly: action.isWebOnly,
        additionalQueryString: action.additionalQueryString,
        shareItemId: action.shareItemId,
        shareItemType: action.shareItemType,
      };
    case "HIDE_POPUP":
      return {
        ...state,
        isOpen: false,
        deepLink: undefined,
        webLink: undefined,
        title: undefined,
        message: undefined,
        ogImageUrl: undefined,
        ogDescription: undefined,
        fallbackUrl: undefined,
        isWebOnly: false,
        branchLink: undefined,
        shareItemId: undefined,
        shareItemType: undefined,
        branchLinkData: undefined,
        additionalQueryString: undefined,
      };
    case "SET_BRANCH_LINK":
      return { ...state, branchLink: action.value };
    case "SET_GENERATED_BRANCH_LINK_DATA":
      return { ...state, branchLinkData: action.value };
    default:
      return state;
  }
};

export const SharePopupContext = React.createContext<
  { dispatch: React.Dispatch<Action> } | undefined
>(undefined);

export const SharePopupProvider: React.FC = ({ children }) => {
  const { firebaseUser } = useFirebaseAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isOpen,
    webLink,
    deepLink,
    branchLink,
    title,
    message,
    ogImageUrl,
    ogDescription,
    fallbackUrl,
    isWebOnly,
    shareItemId,
    shareItemType,
    branchLinkData,
    additionalQueryString,
  } = state;

  useEffect(() => {
    if (webLink && isOpen) {
      let branchDeepLinkDetails: {
        $deeplink?: string;
        $deeplink_v2?: string;
      } = {};

      if (deepLink) {
        branchDeepLinkDetails = {
          $deeplink: deepLink,
          $deeplink_v2: deepLink,
        };
      }

      const branchData: GeneratedBranchLinkDataSchema = {
        campaign: "web-share",
        data: {
          ...branchDeepLinkDetails,
          $sharer_user_id: firebaseUser?.uid,
          $share_link_id: uuidv4(),
          $share_created_date: moment().valueOf().toString(),
          $ios_passive_deepview: "false",
          $android_passive_deepview: "false",
          $desktop_url: webLink,
          $og_image_url: ogImageUrl,
          $og_description: ogDescription,
          $web_only: isWebOnly,
          ...(fallbackUrl ? { $fallback_url: fallbackUrl } : {}),
        },
      };

      Branch.link(cloneDeep(branchData), (err, link) => {
        if (err) console.warn(err);

        dispatch({
          type: "SET_BRANCH_LINK",
          value: err || !link ? webLink : link,
        });

        dispatch({
          type: "SET_GENERATED_BRANCH_LINK_DATA",
          value: branchData,
        });
      });
    }
  }, [
    deepLink,
    webLink,
    isOpen,
    firebaseUser,
    ogImageUrl,
    ogDescription,
    isWebOnly,
    fallbackUrl,
  ]);

  return (
    <SharePopupContext.Provider value={{ dispatch }}>
      <ShareModal
        open={isOpen}
        onClose={() => dispatch({ type: "HIDE_POPUP" })}
        title={title ?? ""}
        message={message ?? ""}
        url={branchLink ?? webLink ?? ""}
        additionalQueryString={additionalQueryString}
        shareItemId={shareItemId}
        shareItemType={shareItemType}
        shareUserId={firebaseUser?.uid}
        shareLinkId={branchLinkData?.data?.$share_link_id}
        shareCreatedDate={branchLinkData?.data?.$share_created_date}
      />
      {children}
    </SharePopupContext.Provider>
  );
};

export function useSharePopup() {
  const context = useContext(SharePopupContext);

  if (!context) {
    throw new Error("useSharePopup needs to be used within SharePopupProvider");
  }

  return context;
}
