export default {
  work: {
    teal: {
      "100": "#E6F3F3",
      "500": "#389295",
      "600": "#2F7A7C",
      "700": "#256163",
    },
    workplaceBlue: {
      "100": "#DFE7F6",
      "400": "#406FCB",
      "500": "#345BA6",
    },
    neutral: {
      "000": "#FFFFFF",
      "050": "#F7F9F9",
      "100": "#EFF3F3",
      "200": "#E6E8E8",
      "300": "#D5D8D8",
      "400": "#AFB4B4",
      "500": "#899090",
      "600": "#636D6D",
      "700": "#272B2B",
      "800": "#1E2121",
      "900": "#151717",
    },
    red: {
      "400": "#DF5C64",
    },
    green: {
      "400": "#6FB36D",
    },
    pink: {
      "400": "#BD3E6F",
    },
    purple: {
      "400": "#6F5FB4",
      "500": "#594C94",
    },
    blue: {
      "50": "#406FCB1A",
      "400": "#599ACF",
    },
    yellow: {
      "400": "#D9A853",
    },
  },
};
