/** This hook keeps the specified elements in a hidden state no matter
 *  what. It observes DOM changes specifically for changes to the hidden
 *  attribute. Useful to hide form elements without any modifications to
 *  the form itself. Elements will still take up space on screen.
 */
import useMutationObserver from "@rooks/use-mutation-observer";
import { useCallback, useRef } from "react";

const useHideElements = (selectors?: string[]) => {
  const docRef = useRef(document.body);

  const handleMutate = useCallback(
    (mutationList: MutationRecord[]) => {
      if (typeof selectors === "undefined") {
        return;
      }

      mutationList.forEach((mutation) => {
        const { type, addedNodes } = mutation;

        const wereNodesAdded = type === "childList" && addedNodes.length;
        if (wereNodesAdded) {
          const matches = docRef.current?.querySelectorAll(selectors.join());

          matches?.forEach((el) => {
            if (window.getComputedStyle(el).visibility !== "hidden") {
              (el as HTMLElement).style.visibility = "hidden";
            }
          });
        }
      });
    },
    [selectors]
  );

  useMutationObserver(docRef, handleMutate);
};

export default useHideElements;
