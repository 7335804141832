import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import FullPageSpinner from "../components/shared/Spinners/FullPageSpinner";
import { AuthState, useFirebaseAuth } from "../hooks/useFirebaseAuth";
import { useSessionAuth } from "../hooks/useSessionAuth";

interface AuthedProps {
  redirectUnauthedTo?: string;
}

const Authed: React.FC<AuthedProps> = ({
  redirectUnauthedTo = "/login",
  children,
}) => {
  const { authState } = useFirebaseAuth();
  const { isCheckingSessionCookie } = useSessionAuth();
  const { pathname, search } = useLocation();

  if (authState === AuthState.PENDING || isCheckingSessionCookie)
    return <FullPageSpinner />;

  if (authState === AuthState.UNAUTHORIZED)
    return <Redirect to={`${redirectUnauthedTo}?next=${pathname}${search}`} />;

  return <>{children}</>;
};

export default Authed;
