import { Box, Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers";
import { AxiosError } from "axios";
import { useFirebaseEventTracking } from "hooks/useFirebaseEventTracking";
import { signupWithEmailAndPassword } from "lib/firebaseAuthHelpers";
import fireEvent from "lib/fireEvent";
import { getApiError } from "lib/services/shared";
import { FirebaseEvent } from "lib/tracking/firebaseEventTracking";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import FormControlErrorMessage from "../ErrorMessage/FormControlErrorMessage";
import TOSCheckbox from "../TOSCheckbox";
import AuthPasswordInput from "./AuthPasswordInput";
import CommonAuthInput from "./CommonAuthInput";
import CommonAuthModalButton from "./CommonAuthModalButton";
import requiredErrorMessage from "./constants";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tosChecked: boolean;
};

const schema = yup.object().shape({
  firstName: yup.string().trim().required(requiredErrorMessage),
  lastName: yup.string().trim().required(requiredErrorMessage),
  email: yup.string().email().required(requiredErrorMessage),
  password: yup.string().required(requiredErrorMessage),
  tosChecked: yup.boolean().required().oneOf([true]),
});

interface AuthSignUpFormProps {
  prefillEmail?: string;
}

const AuthSignUpForm = ({ prefillEmail }: AuthSignUpFormProps) => {
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const { trackClick, trackEvent } = useFirebaseEventTracking();
  const { register, handleSubmit, formState, control, setValue, trigger } =
    useForm<FormData>({
      resolver: yupResolver(schema),
      mode: "onTouched",
      defaultValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        tosChecked: false,
      },
    });
  const { errors, isSubmitting, isValid } = formState;

  useEffect(() => {
    if (prefillEmail) {
      setValue("email", prefillEmail);
    }
  }, [prefillEmail, setValue]);

  const logGaSignUpEvent = () => {
    trackEvent("sign_up");
  };

  const onSubmit = async (data: FormData) => {
    if (!data) {
      return;
    }

    const { firstName, lastName, email, password, tosChecked } = data;

    setAuthError(undefined);
    trackClick(FirebaseEvent.SIGN_UP_CLICKED);

    if (!tosChecked) {
      return;
    }

    try {
      const user = await signupWithEmailAndPassword({
        email: email.trim(),
        firstName,
        lastName,
        password,
        tosChecked,
      });

      if (user) {
        logGaSignUpEvent();
        fireEvent("Authenticated");
      }
    } catch (e) {
      const error = getApiError(e as AxiosError);
      setAuthError(error);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          marginBottom={["13px", "13px", "20px"]}
          flexDirection={["column", "column", "row"]}
        >
          <Box
            marginBottom={["13px", "13px", "0px"]}
            marginRight={["0px", "0px", "16px"]}
          >
            <CommonAuthInput
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              autoFocus
              ref={register}
              error={errors.firstName?.message}
              isInvalid={!!errors.firstName?.message}
            />
          </Box>
          <Box>
            <CommonAuthInput
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              ref={register}
              error={errors.lastName?.message}
              isInvalid={!!errors.lastName?.message}
            />
          </Box>
        </Flex>
        <Box marginBottom={["13px", "13px", "20px"]}>
          <CommonAuthInput
            id="email"
            name="email"
            type="email"
            label="Email"
            ref={register}
            autoComplete="off"
            error={errors.email?.message}
            isInvalid={!!errors.email?.message}
          />
        </Box>
        <Box marginBottom={["36px", "36px", "24px"]}>
          <AuthPasswordInput
            id="password"
            name="password"
            ref={register}
            autoComplete="off"
            error={errors.password?.message}
            isInvalid={!!errors.password?.message}
          />
        </Box>
        <Box marginBottom={["22px", "22px", "24px"]}>
          <Controller
            name="tosChecked"
            control={control}
            render={({ name, value, onChange, onBlur }) => {
              return (
                <TOSCheckbox
                  name={name}
                  id={name}
                  isChecked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    trigger("tosChecked");
                  }}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </Box>
        <CommonAuthModalButton
          type="submit"
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          <Text
            fontSize="16px"
            lineHeight="23px"
            fontWeight={500}
            color="neutral.0"
          >
            Sign up
          </Text>
        </CommonAuthModalButton>
      </form>
      <FormControlErrorMessage
        marginTop="16px"
        textAlign="center"
        paddingX="10px"
        errorMessages={authError}
      />
    </Box>
  );
};

export default AuthSignUpForm;
