import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import React from "react";
import theme from "../../../theme";

interface Props extends FlexProps {
  bg?: string;
}

const FullPageSpinner: React.FC<Props> = ({ bg = "white", ...props }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="fixed"
      w="full"
      h="full"
      bg={bg}
      {...props}
    >
      <Spinner
        data-testid="fullPageSpinner"
        speed="1.0s"
        color={theme.colors.text.darkGray}
        emptyColor="gray.200"
      />
    </Flex>
  );
};

export default FullPageSpinner;
