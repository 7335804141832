import { Box, BoxProps } from "@chakra-ui/react";
import React, { forwardRef, useState } from "react";
import { SharedInputProps } from "../FormControls/SharedInput";
import CommonAuthInput from "./CommonAuthInput";
import PasswordShowHideButton, {
  OnShowHideButtonClickParams,
} from "./PasswordShowHideButton";

interface AuthPasswordInputProps extends SharedInputProps {
  showHideButtonContainerProps?: BoxProps;
}

const AuthPasswordInput = forwardRef<HTMLInputElement, AuthPasswordInputProps>(
  ({ showHideButtonContainerProps = {}, ...props }, ref) => {
    const [inputType, setInputType] = useState<"text" | "password">("password");
    const toggleInputType = ({ show }: OnShowHideButtonClickParams) => {
      setInputType(show ? "text" : "password");
    };

    return (
      <Box position="relative">
        <CommonAuthInput
          type={inputType}
          label="Password"
          ref={ref}
          {...props}
        />
        <PasswordShowHideButton
          top={["51px", "48px", "47px"]}
          onShowHideButtonClick={toggleInputType}
          {...showHideButtonContainerProps}
        />
      </Box>
    );
  }
);

export default AuthPasswordInput;
