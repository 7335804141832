import { Box, BoxProps, CloseButton, Heading, Text } from "@chakra-ui/react";
import React from "react";

// From chakra-ui toast() method
interface IToastCardProps extends BoxProps {
  onClose: () => void;
  id: string;
  hasCloseButton?: boolean;
  color: string;
  title: string;
  body: string;
}

const ToastCard: React.FC<IToastCardProps> = ({
  children,
  onClose,
  id,
  hasCloseButton,
  color,
  title,
  body,
  ...restProps
}) => {
  return (
    <Box
      id={id}
      m={4}
      py={4}
      px={8}
      shadow="lg"
      backgroundColor="white"
      borderRadius={8}
      zIndex={80}
      position="relative"
      {...restProps}
    >
      {hasCloseButton && (
        <CloseButton
          size="md"
          pos="absolute"
          right={3}
          top={3}
          bg="#f5f5f5"
          rounded="full"
          onClick={onClose}
        />
      )}
      <Heading
        fontSize="18px"
        lineHeight="normal"
        letterSpacing="-0.15px"
        color={color}
        fontWeight={700}
        marginTop={2}
      >
        {title}
      </Heading>
      <Text py={2} color="#727272">
        {body}
      </Text>
    </Box>
  );
};

export default ToastCard;
