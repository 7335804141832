import { checkIsPrerender } from "hooks/usePrerender";

interface IGtm {
  pushDataLayer: any;
  dataLayer: {
    reset: () => void;
  };
}

const getGtmFromWindow = (): IGtm | undefined => {
  if (checkIsPrerender()) {
    return;
  }

  const googleTagManager = (window as any).google_tag_manager;
  if (googleTagManager == null) return;
  return googleTagManager[process.env.REACT_APP_GTM_CONTAINER_ID!];
};

function getDatalayer() {
  if (checkIsPrerender()) {
    return;
  }

  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
  }
  return (window as any).dataLayer;
}

export const pushDataLayer = (data: object) => {
  if (checkIsPrerender()) {
    return;
  }

  try {
    getDatalayer().push(data);
  } catch (err) {
    console.log("GTM: caught error pushing datalayer", err);
  }
};

export const reset = () => {
  if (checkIsPrerender()) {
    return;
  }

  const gtm = getGtmFromWindow();
  if (gtm == null) return;
  try {
    gtm.dataLayer.reset();
  } catch (err) {
    console.log("GTM: caught error resetting gtm", err);
  }
};
