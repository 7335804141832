import { Icon } from "@chakra-ui/icons";
import { Box, Button, ButtonProps, Spinner, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { User } from "firebase/auth";
import { signInWithGoogle } from "lib/firebaseAuthHelpers";
import fireEvent from "lib/fireEvent";
import getFirstAndLastName from "lib/getFirstAndLastName";
import { setUserPrivateSettings } from "lib/userRepository";
import React, { ReactElement, useState } from "react";
import { ReactComponent as GoogleIcon } from "styles/icons/google-icon.svg";

export interface Props extends ButtonProps {
  signup?: boolean;
  onSubmit: () => void;
  onComplete: (err?: string, user?: User) => void;
  renderGoogleIcon?: () => ReactElement;
}

const SignInWithGoogle = ({
  signup,
  onSubmit,
  onComplete,
  renderGoogleIcon,
  ...buttonProps
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleGoogleSignIn = () => {
    onSubmit();
    setLoading(true);
    signInWithGoogle()
      .then(async ({ user }) => {
        const firebaseUser = user as User;

        if (signup && firebaseUser?.displayName && firebaseUser?.email) {
          const { firstName, lastName } = getFirstAndLastName(
            firebaseUser.displayName ?? ""
          );

          await setUserPrivateSettings({
            uid: firebaseUser.uid,
            name: firebaseUser.displayName,
            firstName,
            lastName,
            email: firebaseUser.email,
            tosChecked: true,
          });
        }

        onComplete(undefined, firebaseUser ?? undefined);
        fireEvent("Authenticated");
      })
      .catch((error) => onComplete(error))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      width="100%"
      height={["50px", "50px", "48px"]}
      borderRadius="12px"
      bgColor="neutral.0"
      color="neutral.700"
      border="1px solid var(--chakra-colors-neutral-200)"
      position="relative"
      disabled={loading}
      {...buttonProps}
      onClick={handleGoogleSignIn}
    >
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-38%)"
        left="20px"
      >
        {loading ? (
          <Spinner size="sm" color="inherit" />
        ) : (
          <>
            {renderGoogleIcon ? (
              renderGoogleIcon()
            ) : (
              <Box>
                <Icon as={GoogleIcon} width="20px" height="20px" />
              </Box>
            )}
          </>
        )}
      </Box>
      <Text
        fontFamily="Brandon"
        fontSize={["18px", "18px", "16px"]}
        lineHeight={["25px", "25px", "23px"]}
        fontWeight={500}
        color="neutral.700"
      >
        {signup ? (
          <Trans>Sign up with Google</Trans>
        ) : (
          <Trans>Sign in with Google</Trans>
        )}
      </Text>
    </Button>
  );
};

export default SignInWithGoogle;
