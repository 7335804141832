import axios from "axios";
import { FieldValue, serverTimestamp } from "firebase/firestore";
import { firestore } from "./firebase";
import { docRefToArray, docToObject, getTimestamp } from "./firebaseHelpers";
import { DateTime } from "./services/sharedInterfaces";
import { UserSummary } from "./services/users";

type Overwrite<T1, T2> = {
  [P in Exclude<keyof T1, keyof T2>]: T1[P];
} & T2;

export interface FirestoreUser {
  id: string;
  has_avatar: boolean;
  is_publisher?: boolean;
  milestone?: number;
  name: string;
  username: string;
  region?: {
    name: string;
  };
}

//TODO check this type with cyrus, im sure some of these fields are legacy
export interface NoUserRelation {
  is_following: boolean;
}
export interface UserRelation {
  friend_request_received?: boolean;
  friend_request_sent?: boolean;
  friend_request_sent_at?: DateTime;
  friended_at?: DateTime;
  is_friend?: boolean;
  is_following?: boolean;
  user: FirestoreUser;
  synced_at?: FieldValue;
  updated_at?: DateTime;
}

export interface UserRelationFollowing extends UserRelation {
  followed_at: DateTime;
  following_allow_push_notifications: boolean;
  is_following: boolean;
}

interface WithOptionalName {
  name?: string;
}

export const isGuestUser = (user: WithOptionalName) => {
  return (
    user?.name?.startsWith("guest") ||
    user?.name?.startsWith("Guest") ||
    user?.name?.startsWith("user_")
  );
};

export const isTeacher = (user: UserSummary) => {
  return user.is_publisher;
};

export const getName = (user: WithOptionalName | null) => {
  if (user?.name) {
    return isGuestUser(user) ? "Guest" : user.name;
  }

  return "Guest";
};

export const getFirstName = (user: UserSummary) => {
  return getName(user).split(" ")[0];
};

export const getLastName = (user?: UserSummary | null) => {
  if (!user) return;
  if (user?.last_name) return user.last_name;
  return getName(user).split(" ")[1];
};

export const getFirstAndLastName = (name?: string) => {
  const formattedName = name ?? "";
  const firstAnsLastName = formattedName.split(" ");
  const firstName = firstAnsLastName[0];
  const lastName = firstAnsLastName[1];

  return { firstName, lastName };
};

export const getUser = async (id: string) => {
  return firestore
    .collection("users")
    .doc(id)
    .get()
    .then((doc) => {
      return docToObject<UserSummary>(doc);
    });
};

interface GetUpdatedUserInfoParams {
  userId: string;
  onSuccess: (userData: UserSummary) => void;
}
export const getUpdatedUserInfo = ({
  userId,
  onSuccess,
}: GetUpdatedUserInfoParams) => {
  return firestore
    .collection("users")
    .doc(userId)
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const userData = {
          id: snapshot.id,
          ...snapshot.data(),
        } as UserSummary;

        onSuccess(userData);
      }
    });
};

// When server data is missing expected fields, mark those fields
// as optional here so that we know to fix the data before passing
// it through to the application.
// E.g. 'name' is frequently missing from firestore users.
type APIUser = Overwrite<
  UserSummary,
  {
    name?: string;
  }
>;

function parseUser(apiUser: APIUser): UserSummary {
  return {
    ...apiUser,
    name: getName(apiUser), // If a User has no `name`, set a default.
  };
}

export const getUserProfile = async (
  userId: string
): Promise<UserSummary | undefined> => {
  const response = await axios
    .get<APIUser>(
      `${process.env.REACT_APP_USERS_URL}/${userId}/data/user.json`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .catch(() => {
      // TODO: Bring this back if we ever need to report on it.
      // rollbar.error("Failed to get User Profile for", userId);
    });

  if (response) {
    if (!response.data.id) {
      response.data.id = userId;
    }
    return parseUser(response.data);
  }
};

interface PrivateSettingDoc {
  name: string;
  first_name: string;
  last_name: string;
  email?: string;
  tos_date_accepted?: DateTime;
  privacy_policy_accepted_at?: DateTime;
  accepted_privacy_policy_version?: string;
  accepted_terms_of_service_version?: string;
}

interface SetUserPrivateSettingsParams {
  uid: string;
  name: string;
  firstName: string;
  lastName: string;
  email?: string;
  tosChecked: boolean; // Terms and condition checked
}

/**
 * When new user register, use this method to store user details in their private settings.
 * Note: Do not use for other purpose. Use it only in new user register flow.
 */
export const setUserPrivateSettings = async ({
  uid,
  name,
  firstName,
  lastName,
  email,
  tosChecked = false, // Set this to true for auth providers like google signup, apple signup etc
}: SetUserPrivateSettingsParams) => {
  const doc: PrivateSettingDoc = {
    name,
    first_name: firstName,
    last_name: lastName,
    email,
  };

  if (tosChecked) {
    const tosAndPrivacyDateTime = getTimestamp();

    doc.accepted_privacy_policy_version = "2.0";
    doc.accepted_terms_of_service_version = "3.0";
    doc.tos_date_accepted = tosAndPrivacyDateTime;
    doc.privacy_policy_accepted_at = tosAndPrivacyDateTime;
  }

  return firestore
    .doc(`/users/${uid}/private/settings`)
    .set(doc, { merge: true })
    .catch((error) => {
      console.error(
        "userRepository: failed to write to firestore private settings",
        error
      );
    });
};

export const getUserRelation = async (
  userId: string,
  relatedUserId: string
) => {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("user_relation")
    .doc(relatedUserId)
    .get()
    .then((doc) => {
      return docToObject<UserRelation | NoUserRelation>(doc);
    });
};

export const getAllFriends = async (userId: string) => {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("user_relation")
    .where("is_friend", "==", true)
    .get()
    .then((queryRef) => {
      return docRefToArray<UserRelation>(queryRef);
    });
};

export const getAllFriendRequests = async (userId: string) => {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("user_relation")
    .where("is_friend", "==", false)
    .where("friend_request_sent", "==", true)
    .get()
    .then((queryRef) => {
      return docRefToArray<UserRelation>(queryRef);
    });
};

export const setUserFollowingStatus = async (
  followerUserId: string,
  publisherId: string,
  isFollowing: boolean
) => {
  const publisherProfile = await getUserProfile(publisherId);

  if (!publisherProfile?.id) {
    return;
  }

  const { id, has_avatar, is_publisher, milestone, name, username } =
    publisherProfile;
  const publisherData = {
    id,
    has_avatar,
    is_publisher,
    milestone,
    name,
    username,
  };

  const userRelationRef = firestore
    .collection("users")
    .doc(followerUserId)
    .collection("user_relation")
    .doc(publisherId);

  const userRelationData: UserRelationFollowing = {
    followed_at: getTimestamp(),
    following_allow_push_notifications: false,
    is_following: isFollowing,
    user: publisherData,
    synced_at: serverTimestamp(),
    updated_at: getTimestamp(),
  };

  return userRelationRef.set(userRelationData, { merge: true });
};

export const getVerifiedEmailDomains = (userId: string) => {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("settings")
    .get()
    .then((queryRef) => {
      const userPrivateSettings = docToObject<any>(queryRef);

      return userPrivateSettings?.verified_email_domains ?? [];
    });
};
