import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import OrDivider from "../OrDivider";
import AuthSignUpForm from "./AuthSignUpForm";
import AuthSignUpViewHeader from "./AuthSignUpViewHeader";
import { VIEWS } from "./commonTypes";
import SignUpOptionsButton from "./SignUpOptionsButton";

interface AuthSignUpViewProps extends BoxProps {
  showSignUpOptionsOnly?: boolean;
  showSignUpWithEmailOption?: boolean;
  hideSignUpWithAppleBtn?: boolean;
  autofocusSignupWithAppleButton?: boolean;
  prefillEmail?: string;
  setAuthView: (view: VIEWS) => void;
}

const AuthSignUpView = ({
  showSignUpOptionsOnly,
  showSignUpWithEmailOption,
  hideSignUpWithAppleBtn,
  autofocusSignupWithAppleButton,
  prefillEmail,
  setAuthView,
}: AuthSignUpViewProps) => {
  return (
    <Box>
      <Box
        textAlign="center"
        maxW={["300px", "340px", "340px"]}
        width="100%"
        marginX="auto"
        marginBottom={["38px", "35px", "35px"]}
      >
        <AuthSignUpViewHeader
          onLoginButtonClick={() => {
            setAuthView("LOGIN");
          }}
        />
        {!showSignUpOptionsOnly ? (
          <>
            <AuthSignUpForm prefillEmail={prefillEmail} />
            <OrDivider marginY={["30px", "30px", "20px"]} />
          </>
        ) : null}
        <SignUpOptionsButton
          showSignUpWithEmailOption={showSignUpWithEmailOption}
          hideSignUpWithAppleBtn={hideSignUpWithAppleBtn}
          autofocusSignupWithAppleButton={autofocusSignupWithAppleButton}
          setAuthView={setAuthView}
        />
      </Box>
    </Box>
  );
};

export default AuthSignUpView;
