interface SetCookieParams {
  key: string;
  value: string;
  maxAge?: number;
}

const oneDay = 3600 * 24;

export const setCookie = ({ key, value, maxAge = oneDay }: SetCookieParams) => {
  document.cookie = `${key}=${value}; domain=.insighttimer.com; max-age=${maxAge};path=/`;
};
