import { ChakraProvider } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider, css, Global } from "@emotion/react";
import "add-to-calendar-button/assets/css/atcb.min.css";
import "focus-visible/dist/focus-visible";
import { AuthPopupProvider } from "hooks/useAuthPopup";
import { checkIsPrerender } from "hooks/usePrerender";
import setCssScrollBarWidthCustomVar from "lib/setCssScrollBarWidthCustomVar";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import MetaTags from "./components/Events/MetaTags";
import TourManager from "./components/shared/Tours/TourManager";
import { AttendeeInviterProvider } from "./contexts/AttendeeInviterContext";
import { I18nProvider } from "./contexts/I18nContext";
import { ShowProvider } from "./contexts/show/ShowContext";
import { UserSubscriberProvider } from "./contexts/UserSubscriptionContext";
import { FirebaseAuthProvider } from "./hooks/useFirebaseAuth";
import { FirebaseEventTrackingProvider } from "./hooks/useFirebaseEventTracking";
import { PageViewTrackerProvider } from "./hooks/usePageViewTracker";
import { SharePopupProvider } from "./hooks/useSharePopup";
import { init as initGA } from "./lib/analytics/ga";
import { initGTM } from "./lib/analytics/initGTM";
import * as Branch from "./lib/branch";
import { isDevelopment } from "./lib/environment";
import { initFacebookSdk } from "./lib/legacy/auth/initFacebookSdk";
import Routes from "./Routes";
import "./styles/fonts/brandon.scss";
import "./styles/fonts/proxima.scss";
import theme from "./theme";

// To hide blue border everywhere
// https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
const GlobalStyles = css`
  :root {
    --scrollbar-width: 16px;
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  .pac-container {
    z-index: 10000 !important;
  }

  html.default-scrollbar {
    overflow-y: scroll;
  }

  html.overflow-hidden {
    overflow: hidden;
  }

  html.preserve-scroll-width {
    padding-right: var(--scrollbar-width);
  }

  html.preserve-scroll-width #site-header {
    padding-right: var(--scrollbar-width);
  }
`;

Branch.init((branchData) => {
  Branch.trackUserVisitedBranchLinkEvent(branchData);
  Branch.saveUTMs(window.location, branchData);
});

declare global {
  interface Window {
    prerenderReady?: boolean;
  }
}

const emotionCache = createCache({
  key: "insight",
  speedy: !checkIsPrerender(),
});

const App: React.FC = () => {
  useEffect(() => {
    initGA();
    initFacebookSdk();
    setCssScrollBarWidthCustomVar();

    if (!isDevelopment) {
      initGTM();
    }
  }, []);

  return (
    <I18nProvider>
      <React.StrictMode>
        <CacheProvider value={emotionCache}>
          <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <ShowProvider>
              <HelmetProvider>
                <FirebaseAuthProvider>
                  <UserSubscriberProvider>
                    <Helmet>
                      {checkIsPrerender() && (
                        <script>
                          {`
                            setTimeout(function () {
                              window.prerenderReady = true;
                            }, 5000);
                        `}
                        </script>
                      )}
                    </Helmet>
                    <MetaTags />
                    <BrowserRouter>
                      <AttendeeInviterProvider>
                        <PageViewTrackerProvider>
                          <FirebaseEventTrackingProvider>
                            <AuthPopupProvider>
                              <SharePopupProvider>
                                <Routes />
                              </SharePopupProvider>
                            </AuthPopupProvider>
                          </FirebaseEventTrackingProvider>
                        </PageViewTrackerProvider>
                      </AttendeeInviterProvider>
                      <TourManager />
                    </BrowserRouter>
                  </UserSubscriberProvider>
                </FirebaseAuthProvider>
              </HelmetProvider>
            </ShowProvider>
          </ChakraProvider>
        </CacheProvider>
      </React.StrictMode>
    </I18nProvider>
  );
};

export default App;
