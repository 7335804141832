import React, { lazy, Suspense } from "react";
import {
  isEdgeChromium,
  isIE,
  isLegacyEdge,
  osName,
} from "react-device-detect";
import { Route, RouteProps } from "react-router-dom";
import Authed from "./layouts/Authed";
import { isProduction, isWebapp } from "./lib/environment";
import PleaseUpgradeBrowser from "./pages/PleaseUpgradeBrowserPage";

const Production = lazy(() => import("./routes/production"));
const Webapp = lazy(() => import("./routes/webapp"));
const Development = lazy(() => import("./routes/development"));

const isUnsupportedEdge =
  osName === "Windows" && isLegacyEdge && !isEdgeChromium;
const isUnsupportedBrowser = isUnsupportedEdge || isIE;

const Unsupported = () => {
  return <Route path="/" component={PleaseUpgradeBrowser} />;
};

const Routes = () => {
  if (isUnsupportedBrowser) {
    return <Unsupported />;
  }

  if (isProduction) {
    return (
      <Suspense fallback={null}>
        <Production />
      </Suspense>
    );
  }

  if (isWebapp) {
    return (
      <Suspense fallback={null}>
        <Webapp />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={null}>
      <Development />
    </Suspense>
  );
};

export const AuthedRoute: React.FC<RouteProps> = (props) => {
  return (
    <Authed>
      <Route {...props} />
    </Authed>
  );
};

export default Routes;
