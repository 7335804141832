import { Heading, Text } from "@chakra-ui/react";
import React from "react";
import theme from "../../theme";

interface IMessageProps {
  color: string;
  title: string;
  body: string;
}

const Message = ({ color, title, body }: IMessageProps) => (
  <>
    <Heading
      fontSize="18px"
      lineHeight="normal"
      letterSpacing="-0.15px"
      color={color}
      fontWeight={700}
      marginTop={2}
    >
      {title}
    </Heading>
    <Text py={2} color={theme.colors.text.lightGray}>
      {body}
    </Text>
  </>
);

export default Message;
