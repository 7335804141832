import { theme } from "@chakra-ui/react";
import workTheme from "./components/Workplace/theme";

// N.B. If we override breakpoints, please ensure we use
// "em" units, otherwise `useBreakpoint` will fail ruinously.
export default {
  ...theme,
  styles: {
    global: {
      body: {
        paddingRight: "0 !important",
      },
    },
  },
  fonts: {
    ...theme.fonts,
    body: "ProximaNova, Helvetica Neue, Helvetica, Segoe UI, Arial, system-ui, -apple-system, sans-serif",
    heading:
      "ProximaNova, Helvetica Neue, Helvetica, Segoe UI, Arial, system-ui, -apple-system, sans-serif",
  },
  colors: {
    ...theme.colors,
    neutral: {
      "0": "#FFFFFF",
      "000": "#FFFFFF",
      "050": "#F7F9F9",
      "100": "#EFF3F3",
      "150": "#F1F4F4",
      "200": "#E6E8E8",
      "300": "#D5D8D8",
      "400": "#AFB4B4",
      "500": "#899090",
      "600": "#636D6D",
      "700": "#272B2B",
      "800": "#1E2121",
      "900": "#151717",
    },
    maroon: "#A2084D",
    OFteal: "#06868A", // `teal` is a reserved color name in chakra-ui
    gold: "#E1B033",
    text: {
      darkGray: "#181818",
      medDarkGray: "#222222",
      lightGray: "#727272",
    },
    status: {
      green: "#85c93d",
      red: "#f2454f",
      orange: "#ff8106",
      blue: "#4b7bf8",
    },
    workshops: {
      blue: { "400": "#599ACF", "500": "#4C84B2" },
    },
    groups: {
      green: {
        "800": "#056D70",
        "600": "#06868A",
        "100": "#E7F7F8",
      },
      pink: {
        "800": "#A10A44",
        "600": "#B90C4E",
        "100": "#FAE1EA",
      },
      red: {
        "800": "#D93D47",
        "600": "#F2454F",
        "100": "#FFE4E6",
      },
      blue: {
        "300": "#EBF3F9",
        "400": "#599ACF",
      },
      dark: {
        "800": "#222222",
        "700": "#5A5A5A",
        "600": "#9A9A9A",
        "500": "#D2D2D2",
      },
      light: {
        "400": "#ECECEC",
        "300": "#F5F5F5",
        "200": "#FAFAFA",
        "100": "#FFFFFF",
      },
    },
    charts: {
      green: {
        "200": "#A2D7D9",
        "300": "#6ABBBE",
        "400": "#1C989C",
        "500": "#398388", // use for line charts
        "600": "#0D7F83",
      },
      heading: "#3B3B3B",
      label: "#222222",
      unit: "#9A9A9A",
    },
    brandTeal: {
      100: "#D8EEEF",
      500: "#389295",
    },
    ...workTheme,
  },
  components: {
    ...theme.components,
    Button: {
      ...theme.components.Button,
      baseStyle: {
        rounded: "0.25rem",
        fontWeight: 600,
        fontFamily: "Brandon",
        _disabled: {
          opacity: 0.4,
          boxShadow: "none",
        },
        _focus: {
          outline: "none", // TODO: find alternative, this is not accessible
        },
      },
    },
    CloseButton: {
      ...theme.components.CloseButton,
      baseStyle: {
        _focus: {
          outline: "none", // TODO: find alternative, this is not accessible
        },
      },
    },
  },
};
