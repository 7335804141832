import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const CommonAuthModalButton = (props: ButtonProps) => {
  return (
    <Button
      height={["50px", "48px", "48px"]}
      width="100%"
      padding={[
        "9px 10px 12px 10px",
        "10px 10px 14px 10px",
        "10px 10px 14px 10px",
      ]}
      borderRadius={["12px", "10px", "10px"]}
      backgroundColor="teal.500"
      color="neutral.0"
      _hover={{
        backgroundColor: "teal.500",
        color: "neutral.0",
      }}
      _active={{
        backgroundColor: "teal.500",
        color: "neutral.0",
      }}
      cursor={props.disabled ? "auto" : "pointer"}
      {...props}
    />
  );
};

export default CommonAuthModalButton;
