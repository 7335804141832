import { Icon } from "@chakra-ui/icons";
import { Box, Button, ButtonProps, Spinner, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { User } from "firebase/auth";
import { signInWithApple } from "lib/firebaseAuthHelpers";
import fireEvent from "lib/fireEvent";
import getFirstAndLastName from "lib/getFirstAndLastName";
import { setUserPrivateSettings } from "lib/userRepository";
import React, { ReactElement, useState } from "react";
import { ReactComponent as AppleIcon } from "styles/icons/apple-icon.svg";

export interface Props extends ButtonProps {
  signup?: boolean;
  onSubmit: () => void;
  onComplete: (err?: string, user?: User) => void;
  renderAppleIcon?: () => ReactElement;
}

const SignInWithApple: React.FC<Props> = ({
  signup,
  onSubmit,
  onComplete,
  renderAppleIcon,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleAppleSignIn = () => {
    onSubmit();
    setLoading(true);
    signInWithApple()
      .then(async ({ user }) => {
        const firebaseUser = user as User;

        if (signup && firebaseUser?.uid) {
          const { firstName, lastName } = getFirstAndLastName(
            firebaseUser.displayName ?? ""
          );

          await setUserPrivateSettings({
            uid: firebaseUser.uid,
            name: firebaseUser.displayName ?? "",
            firstName,
            lastName,
            email: firebaseUser.email ?? "",
            tosChecked: true,
          });
        }

        onComplete(undefined, firebaseUser ?? undefined);
        fireEvent("Authenticated");
      })
      .catch((error) => onComplete(error))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      width="100%"
      height={["50px", "50px", "48px"]}
      borderRadius="12px"
      bgColor="neutral.700"
      color="neutral.0"
      position="relative"
      disabled={loading}
      {...buttonProps}
      onClick={handleAppleSignIn}
    >
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-38%)"
        left="20px"
      >
        {loading ? (
          <Spinner size="sm" color="inherit" />
        ) : (
          <>
            {renderAppleIcon ? (
              renderAppleIcon()
            ) : (
              <Box>
                <Icon
                  as={AppleIcon}
                  fill="neutral.0"
                  width="16px"
                  height="21px"
                />
              </Box>
            )}
          </>
        )}
      </Box>
      <Text
        fontFamily="Brandon"
        fontSize={["18px", "18px", "16px"]}
        lineHeight={["25px", "25px", "23px"]}
        fontWeight={500}
        color="neutral.0"
      >
        {signup ? (
          <Trans>Sign up with Apple</Trans>
        ) : (
          <Trans>Sign in with Apple</Trans>
        )}
      </Text>
    </Button>
  );
};

export default SignInWithApple;
