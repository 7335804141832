import { checkIsPrerender } from "hooks/usePrerender";
import ReactGA from "react-ga";

export const init = () => {
  if (checkIsPrerender()) {
    return;
  }

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY!);
};

export const pageView = (path?: string) => {
  if (checkIsPrerender()) {
    return;
  }

  ReactGA.pageview(path || window.location.pathname + window.location.search);
};
