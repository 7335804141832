import { Box, Button, ButtonProps, Icon, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import React, { ReactElement } from "react";
import { ReactComponent as EnvelopeIcon } from "styles/icons/envelope-icon.svg";

interface SignUpWithEmailButtonProps extends ButtonProps {
  renderEmailIcon?: () => ReactElement;
}

const SignUpWithEmailButton = ({
  renderEmailIcon,
  ...buttonProps
}: SignUpWithEmailButtonProps) => {
  return (
    <Button
      width="100%"
      height={["50px", "50px", "48px"]}
      borderRadius="12px"
      bgColor="neutral.0"
      color="neutral.700"
      border="1px solid var(--chakra-colors-neutral-200)"
      position="relative"
      {...buttonProps}
    >
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-38%)"
        left="20px"
      >
        {renderEmailIcon ? (
          renderEmailIcon()
        ) : (
          <Box>
            <Icon
              as={EnvelopeIcon}
              width="20px"
              height="16px"
              fill="transparent"
            />
          </Box>
        )}
      </Box>
      <Text
        fontFamily="Brandon"
        fontSize={["18px", "18px", "16px"]}
        lineHeight={["25px", "25px", "23px"]}
        fontWeight={500}
        color="neutral.700"
      >
        <Trans>Sign up with Email</Trans>
      </Text>
    </Button>
  );
};

export default SignUpWithEmailButton;
