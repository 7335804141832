import { Box, Link, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers";
import { AxiosError } from "axios";
import { WEB_FUNNEL_PROD_URL } from "constants/sharedConstants";
import { useFirebaseEventTracking } from "hooks/useFirebaseEventTracking";
import { loginWithEmailAndPassword } from "lib/firebaseAuthHelpers";
import fireEvent from "lib/fireEvent";
import { getApiError } from "lib/services/shared";
import { FirebaseEvent } from "lib/tracking/firebaseEventTracking";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FormControlErrorMessage from "../ErrorMessage/FormControlErrorMessage";
import AuthPasswordInput from "./AuthPasswordInput";
import CommonAuthInput from "./CommonAuthInput";
import CommonAuthModalButton from "./CommonAuthModalButton";

type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const AuthLoginForm = () => {
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const { trackClick, trackEvent } = useFirebaseEventTracking();
  const { register, handleSubmit, formState } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });
  const { errors, isSubmitting, isValid } = formState;
  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    if (!email?.trim() || !password) {
      return;
    }

    trackClick(FirebaseEvent.SIGN_IN_CLICKED);
    setAuthError(undefined);

    try {
      const { user } = await loginWithEmailAndPassword(email, password);

      if (user) {
        fireEvent("Authenticated");
        trackEvent("login");
      }
    } catch (e) {
      const error = getApiError(e as AxiosError);

      setAuthError(error);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom="20px">
          <CommonAuthInput
            id="email"
            name="email"
            type="email"
            label="Email"
            autoFocus
            ref={register}
            isInvalid={!!errors.email?.message}
          />
        </Box>
        <Box marginBottom={["14px", "12px", "12px"]}>
          <AuthPasswordInput
            id="password"
            name="password"
            ref={register}
            isInvalid={!!errors.password?.message}
          />
        </Box>
        <Box marginBottom={["30px", "25px", "25px"]} textAlign="center">
          <Link
            href={`${WEB_FUNNEL_PROD_URL}/reset-password`}
            target="_blank"
            fontFamily="Brandon"
            fontSize="16px"
            lineHeight="23px"
            fontWeight={500}
            color="neutral.600"
          >
            Forgot password
          </Link>
        </Box>
        <CommonAuthModalButton
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          <Text
            fontFamily="Brandon"
            fontSize="16px"
            lineHeight="23px"
            fontWeight={500}
            color="neutral.0"
          >
            Sign in
          </Text>
        </CommonAuthModalButton>
      </form>
      <FormControlErrorMessage
        marginTop="16px"
        textAlign="center"
        paddingX="10px"
        errorMessages={authError}
      />
    </Box>
  );
};

export default AuthLoginForm;
