import { Modal, ModalProps, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect } from "react";

export type SharedModalProps = ModalProps;

const SharedModal = (props: SharedModalProps) => {
  const { isOpen } = props;
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (!htmlElement) {
      return;
    }

    if (isOpen) {
      htmlElement.classList.add("overflow-hidden");
    } else {
      htmlElement.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  useEffect(() => {
    const htmlEl = document.documentElement;

    if (!htmlEl) {
      return;
    }

    if (isMobile && isOpen) {
      htmlEl.classList.add("modal-opened");
    } else {
      htmlEl.classList.remove("modal-opened");
    }
  }, [isMobile, isOpen]);

  return <Modal blockScrollOnMount preserveScrollBarGap {...props} />;
};

export default SharedModal;
