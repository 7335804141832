import { VStack } from "@chakra-ui/react";
import { useFirebaseEventTracking } from "hooks/useFirebaseEventTracking";
import { FirebaseEvent } from "lib/tracking/firebaseEventTracking";
import React, { useState } from "react";
import FormControlErrorMessage from "../ErrorMessage/FormControlErrorMessage";
import SignInWithApple from "../SignInWithApple";
import SignInWithGoogle from "../SignInWithGoogle";
import SignUpWithEmailButton from "../SignUpWithEmailButton";
import { VIEWS } from "./commonTypes";

interface SignUpOptionsButtonProps {
  showSignUpWithEmailOption?: boolean;
  hideSignUpWithAppleBtn?: boolean;
  autofocusSignupWithAppleButton?: boolean;
  setAuthView?: (view: VIEWS) => void;
}

const SignUpOptionsButton = ({
  showSignUpWithEmailOption,
  hideSignUpWithAppleBtn,
  autofocusSignupWithAppleButton,
  setAuthView,
}: SignUpOptionsButtonProps) => {
  const { trackClick, trackEvent } = useFirebaseEventTracking();
  const [providerError, setProviderError] = useState<string | undefined>(
    undefined
  );
  const logGASignupEvent = () => {
    trackEvent("sign_up");
  };

  const onProviderAuthProcessComplete = (errorMessage?: string) => {
    const closedByUserError = errorMessage?.includes(
      "auth/popup-closed-by-user"
    );

    if (errorMessage && !closedByUserError) {
      setProviderError(errorMessage);
    } else {
      logGASignupEvent();
    }
  };

  return (
    <VStack spacing="16px">
      {!hideSignUpWithAppleBtn && (
        <SignInWithApple
          signup
          autoFocus={autofocusSignupWithAppleButton}
          onSubmit={() => {
            setProviderError(undefined);

            trackClick(FirebaseEvent.APPLE_SIGN_UP_CLICKED);
          }}
          onComplete={onProviderAuthProcessComplete}
        />
      )}
      <SignInWithGoogle
        signup
        onSubmit={() => {
          setProviderError(undefined);

          trackClick(FirebaseEvent.GOOGLE_SIGN_UP_CLICKED);
        }}
        onComplete={onProviderAuthProcessComplete}
      />
      {showSignUpWithEmailOption && (
        <SignUpWithEmailButton
          onClick={() => {
            if (setAuthView) {
              setAuthView("SIGN_UP_EMAIL_VIEW");
            }
          }}
        />
      )}
      <FormControlErrorMessage
        errorMessages={providerError}
        textAlign="center"
      />
    </VStack>
  );
};

export default SignUpOptionsButton;
