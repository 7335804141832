import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

const SharedLabel = (props: FormLabelProps) => {
  return (
    <FormLabel
      fontFamily="Brandon"
      fontSize="16px"
      lineHeight="23px"
      fontWeight={700}
      color="neutral.700"
      cursor="pointer"
      {...props}
    />
  );
};

export default SharedLabel;
