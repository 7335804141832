/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from "react";


const tourKeys = ["intro-hosting"] as const;

export type TourKey = typeof tourKeys[number];
export const isValidTourKey = (key: string | null): key is TourKey =>
  tourKeys.includes(key as TourKey);
const isInputElement = (el: Element | null): el is HTMLInputElement =>
  (el as HTMLInputElement).value !== undefined;
const isTextAreaElement = (el: Element | null): el is HTMLTextAreaElement =>
  (el as HTMLTextAreaElement).type === "textarea";
const isSelectElement = (el: Element | null): el is HTMLSelectElement =>
  (el as HTMLSelectElement).type.startsWith("select-");
export interface ITourStep {
  selector: string;
  route: string | ((data: { [k: string]: any }) => string);
  heading?: string;
  body: ReactNode;
  validator?: (data: { [k: string]: any }) => boolean;
  onClick?: (setData: (data: { [k: string]: any }) => void, e: Event) => void;
  hasNextButton?: boolean;
  onNext?: (setData: (data: { [k: string]: any }) => void) => void;
  disabledElements?: string[];
  hiddenElements?: string[];
  resumeIndex?: number;
}

export interface ITourStore {
  activeTour: string | null;
  steps: ITourStep[];
  currentIndex: number;
  resumeIndex: number;
  tourData: { [k: string]: any };
}

type TourSteps = { [k in TourKey]: ITourStep[] };

const tourSteps: TourSteps = {
  "intro-hosting": [
    {
      selector: "create-button",
      route: "/live/hosting",
      heading: "Click to begin",
      body: "This tour will guide you as you create a new Live event - a test event - and show you all of the key features you should be aware of as a host. When you're ready, click 'Create your event' above.",
      onClick: (setData) => setData({ isCreateClicked: true }),
      validator: (tourData) => tourData.isCreateClicked,
    },
    {
      selector: "check-cam",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Check your camera",
      body: `Before your event starts, take a moment to check your cam and mic. Your cam image should be visible if it is selected.
        For real events scheduled to begin in the future, you can enter this screen from the event Lobby, by pressing "Enter Stream Early".`,
      hasNextButton: true,
      // NOTE: "Enter Stream Early" breaks tour flow, disabling it as work-around
      disabledElements: ["#enter-early"],
      hiddenElements: ["#host-get-ready-close"],
    },
    {
      selector: "mic-volume",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Check your microphone",
      body: "Speak loudly and clearly into your mic; if it is working, this bar will animate.",
      hasNextButton: true,
    },
    {
      selector: "mic-playback",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Check your microphone",
      body: "Toggle this switch to play back your mic through your speakers. Try it now to continue the tour.",
      onClick: (setData) => setData({ isMicPlaybackToggled: true }),
      validator: (tourData) => tourData.isMicPlaybackToggled,
    },
    // TODO: how to keep focus for HostControls? Break down into more steps.
    {
      selector: "av-settings",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Change advanced settings",
      body: "Click here to open audio-visual settings. This is where you can change cam orientation, select a different device, or change quality settings. Click it now to continue the tour.",
      onClick: (setData) => setData({ isAVSettingsClicked: true }),
      validator: (tourData) => tourData.isAVSettingsClicked,
    },
    {
      selector: "share-button",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Share your event",
      body: "Use these options to promote your event on social media.",
      hasNextButton: true,
    },
    {
      selector: "comments",
      route: (tourData) => `/live/${tourData.eventId}`,
      body: "You can write chat messages for your attendees here.",
      hasNextButton: true,
    },
    {
      selector: "start-end-button",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Click here when you're ready to start streaming",
      body: "When your video and audio are set up, press Start Live. Please note that you can only start the event at the scheduled start time.",
      onClick: (setData) => {
        setData({ isStartLivePressed: true });
      },
      validator: (tourData) => tourData.isStartLivePressed,
    },
    {
      selector: "start-end-button",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Click here to end your stream",
      body: "When your event is finished, press End Live. You'll be prompted to confirm that you want to end your event.",
      onClick: (setData) => {
        setData({ isEndLivePressed: true });
      },
      validator: (tourData) => tourData.isEndLivePressed,
    },
    {
      selector: "confirm-end",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Confirmation",
      body: "This will stop broadcasting your video and audio and end the event for you and all of your attendees.",
      onClick: (setData) => {
        setData({ isEventEnded: true });
      },
      validator: (tourData) => tourData.isEventEnded,
    },
    {
      selector: "view-replay",
      route: (tourData) => `/live/${tourData.eventId}`,
      heading: "Congratulations!",
      body: "You've just created and presented your first live event! A replay of your event will be available here - please note it will take a few minutes to be available.",
      hasNextButton: true,
    },
  ],
};

export const createStore = (
  activeTour: TourKey | "",
  currentIndex = 0,
  tourData = {}
): ITourStore => {
  const steps: ITourStep[] = activeTour ? tourSteps[activeTour] : [];
  const resumeIndex = steps.length
    ? steps[currentIndex].resumeIndex ?? currentIndex
    : 0;
  return {
    activeTour,
    steps,
    currentIndex,
    resumeIndex,
    tourData,
  };
};

export const getCurrentStore = (): ITourStore | null =>
  JSON.parse(localStorage.getItem("tour-store") || "null");

export const setCurrentStore = (newStore: ITourStore | null) =>
  localStorage.setItem("tour-store", JSON.stringify(newStore));

const validateForm = () => {
  document.dispatchEvent(new Event("validate-form"));
};

export default tourSteps;
