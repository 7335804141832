import { logEvent } from "@firebase/analytics";
import branch, { SessionData } from "branch-sdk";
import { checkIsPrerender } from "hooks/usePrerender";
import { isDevelopment } from "lib/environment";
import { analytics } from "lib/firebase";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";

export interface BranchParsedDataSchema {
  $android_passive_deepview?: string; // boolean value in the form of string
  $canonical_url?: string;
  $deeplink?: string;
  $deeplink_v2?: string;
  $desktop_url?: string;
  $identity_id?: number;
  $ios_passive_deepview?: string; // boolean value in the form of string
  $og_description?: string;
  $og_title?: string;
  $one_time_use?: boolean;
  $share_created_date?: string;
  $share_link_id?: string;
  $sharer_user_id?: string;
  "+click_timestamp"?: number;
  "+clicked_branch_link"?: boolean;
  "+is_first_session"?: boolean;
  "+match_guaranteed"?: boolean;
  "~campaign"?: string;
  "~creation_source"?: number;
  "~id"?: number;
  "~referring_link"?: string;
  "~channel"?: string;
  "~feature"?: string;
  tags?: string; // value of "utm_term" field available in querystring of url
}

type TBranchDataCallback = (data: SessionData | null) => void;

type TPageViewData = Record<string, any>;

export let initialized = false;

export function init(cb: TBranchDataCallback) {
  if (checkIsPrerender()) {
    return;
  }

  initialized = true;
  return branch.init(
    process.env.REACT_APP_BRANCH_API_KEY!,
    {},
    (error, branchData) => {
      if (error != null) {
        console.warn("branch.init: ERROR", error);
      }
      cb(branchData);
    }
  );
}

export function link(
  linkData: branch.DeepLinkData,
  cb: (error: branch.BranchError, link: string | null) => void
) {
  if (checkIsPrerender()) {
    return;
  }

  if (!initialized) {
    init(() => {
      branch.link(linkData, cb);
    });
  } else {
    branch.link(linkData, cb);
  }
}

export function data(cb: TBranchDataCallback) {
  if (checkIsPrerender()) {
    return;
  }

  return branch.data((error, branchData) => {
    if (error != null) {
      console.warn("branch.data: ERROR", error);
    }
    cb(branchData);
  });
}

export function setBranchViewData(pageViewData: TPageViewData) {
  try {
    if (pageViewData && !isEmpty(pageViewData)) {
      console.log(`branch.setBranchViewData: ${Object.keys(pageViewData)}`);
      branch.setBranchViewData({ data: pageViewData });
    }
  } catch (err) {
    console.warn("branch.setBranchViewData: ERROR", err);
  }
}

export function trackPageView() {
  try {
    if (branch) {
      branch.track("pageview");
    }
  } catch (err) {
    console.warn("branch.trackPageView: ERROR", err);
  }
}

interface IBranchDataParsed {
  $sharer_user_id?: string;
}

export function getSharerUserId(branchData: SessionData | null) {
  if (branchData != null) {
    const data_parsed: IBranchDataParsed = branchData.data_parsed;
    return data_parsed.$sharer_user_id;
  }
}

export function getEventSharerUserId(eventId: string) {
  return localStorage.getItem(`IT-eventSharerUserIdData-${eventId}`);
}

export function saveEventSharerUserId(eventId: string, userId: string) {
  localStorage.setItem(`IT-eventSharerUserIdData-${eventId}`, userId);
}

/**
 * save utms, either from url query or from branch data.
 */
export const saveUTMs = (
  location: Location,
  utmSessionData: SessionData | null
) => {
  const data_parsed: BranchParsedDataSchema | undefined =
    utmSessionData?.data_parsed;
  const queries = queryString.parse(location.search);
  const utmData: Record<string, any> = {};

  // only store relevant utm values (if present)
  if (data_parsed && !isEmpty(data_parsed)) {
    // save utms from branch
    if (data_parsed["~campaign"])
      utmData.utm_campaign = data_parsed["~campaign"];
    if (data_parsed["~channel"]) utmData.utm_source = data_parsed["~channel"];
    if (data_parsed["~feature"]) utmData.utm_medium = data_parsed["~feature"];

    if (data_parsed["$sharer_user_id"])
      utmData["$sharer_user_id"] = data_parsed["$sharer_user_id"];
    if (data_parsed["$share_link_id"])
      utmData["$share_link_id"] = data_parsed["$share_link_id"];
    if (data_parsed["$share_created_date"])
      utmData["$share_created_date"] = data_parsed["$share_created_date"];
  } else if (queries) {
    // save utms from query parameters
    if (queries["utm_source"]) utmData["utm_source"] = queries["utm_source"];
    if (queries["utm_medium"]) utmData["utm_medium"] = queries["utm_medium"];
    if (queries["utm_campaign"])
      utmData["utm_campaign"] = queries["utm_campaign"];
    if (queries["utm_content"]) utmData["utm_content"] = queries["utm_content"];
    if (queries["utm_term"]) utmData["utm_term"] = queries["utm_term"];
  }

  if (!isEmpty(utmData)) {
    const utmDataJson = JSON.stringify(utmData);
    // only set the data if there is any present
    localStorage.setItem("IT-branchAttributionData", utmDataJson);
  }
};

export const analyticsQuery = (tag: string) => {
  const savedBranchData = localStorage.getItem("IT-branchAttributionData");
  const branchData = savedBranchData && JSON.parse(savedBranchData);

  const analyticData: Record<string, string | number> = {};
  const referrer = getReferrer();

  analyticData["~channel"] =
    (branchData && branchData.utm_source) || referrer.domain;
  analyticData["~feature"] =
    (branchData && branchData.utm_medium) || referrer.medium;

  if (branchData && branchData.utm_campaign)
    analyticData["~campaign"] = branchData.utm_campaign;

  if (branchData && branchData.utm_content)
    analyticData["~keyword"] = branchData.utm_content;

  if (branchData?.$sharer_user_id)
    analyticData.$sharer_user_id = branchData.$sharer_user_id;

  if (branchData && branchData.utm_term)
    analyticData.tags = branchData.utm_term;

  return queryString.stringify(analyticData) + (tag ? `&tags=${tag}` : "");
};

const getReferrer = () => {
  const a = document.createElement("a");
  a.href = document.referrer;

  let domain = a.hostname;
  let medium = "referral";

  if (domain.startsWith("m.")) {
    domain = domain.replace(/^m\./, "");
  } else {
    domain = domain.replace(/^www\./, "");
  }

  if (domain.startsWith("insighttimer.com")) {
    domain = "(direct)";
    medium = "(none)";
  } else if (domain.startsWith("google.")) {
    domain = "google";
    medium = "organic";
  } else if (domain.startsWith("yahoo.")) {
    domain = "yahoo";
    medium = "organic";
  } else if (domain.startsWith("bing.")) {
    domain = "bing";
    medium = "organic";
  } else if (domain.startsWith("duckduckgo.")) {
    domain = "duckduckgo";
    medium = "organic";
  } else if (searchEngines.includes(domain)) {
    medium = "organic";
  }

  return {
    domain,
    medium,
  };
};

interface GetAppDownloadLinkParams {
  branchAppData?: BranchParsedDataSchema;
}

/**
 * Used to get app download link based on predefined base url "https://insig.ht/appDownload" and optional branch details in query string format.
 */
export const getAppDownloadLink = ({
  branchAppData,
}: GetAppDownloadLinkParams) => {
  const details = branchAppData ?? {};
  const branchDetailsInStringFormat = queryString.stringify(details);
  const query = branchDetailsInStringFormat
    ? `?${branchDetailsInStringFormat}`
    : "";

  return `https://insig.ht/appDownload${query}`;
};

const searchEngines = [
  "360.cn",
  "alice.com",
  "aliceadsl.fr",
  "alltheweb.com",
  "altavista.com",
  "aol.com",
  "ask.com",
  "search.aol.fr",
  "alicesuche.aol.de",
  "search.auone.jp",
  "isearch.avg.com",
  "search.babylon.com",
  "baidu.com",
  "biglobe.ne.jp",
  "bing.com",
  "search.centrum.cz",
  "search.comcast.net",
  "search.conduit.com",
  "daum.net",
  "ekolay.net",
  "eniro.se",
  "go.mail.ru",
  "goo.ne.jp",
  "search.incredimail.com",
  "kvasir.no",
  "bing.com",
  "lycos.com",
  "search.lycos.de",
  "mamma.com",
  "msn.com",
  "money.msn.com",
  "local.msn.com",
  "mynet.com",
  "najdi.si",
  "naver.com",
  "search.netscape.com",
  "szukaj.onet.pl",
  "ozu.es",
  "rakuten.co.jp",
  "rambler.ru",
  "search-results.com",
  "search.smt.docomo.ne.jp",
  "sesam.no",
  "seznam.cz",
  "sogou.com",
  "szukacz.pl",
  "buscador.terra.com.br",
  "search.tut.by",
  "search.ukr.net",
  "search.virgilio.it",
  "voila.fr",
  "wp.pl",
  "yahoo.com",
  "yahoo.cn",
  "yahoo.com",
  "google.com",
  "yandex.com",
  "yandex.ru",
  "yam.com",
];

/**
 * Use to track branch link opened event if user open branch link in browser.
 */
export const trackUserVisitedBranchLinkEvent = (
  branchData: SessionData | null
) => {
  if (!branchData || isEmpty(branchData.data_parsed) || checkIsPrerender()) {
    return null;
  }

  const parsedData: BranchParsedDataSchema = branchData.data_parsed;
  const branchReferringLink = parsedData["~referring_link"];

  if (!branchReferringLink) {
    return;
  }

  const eventParams = {
    event_name: "service_event",
    service_event_name: "web_opened_by_deep_link",
    deep_link_type: "branch",
    campaign: parsedData["~campaign"],
    click_timestamp: parsedData["+click_timestamp"],
    deeplink: parsedData["$deeplink"],
    is_first_session: parsedData["+is_first_session"],
    share_created_date: parsedData.$share_created_date,
    share_link_id: parsedData.$share_link_id,
    invite_link: branchReferringLink,
  };

  if (isDevelopment) {
    console.log({
      en: "service_event",
      ...eventParams,
    });

    return;
  }

  logEvent(analytics, "service_event", eventParams);
};
