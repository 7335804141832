/** This hook keeps the specified elements in a disabled state no matter
 *  what. It observes DOM changes specifically for changes to the disabled
 *  attribute. Useful to disable form elements without any modifications to
 *  the form itself.
 */
import useMutationObserver from "@rooks/use-mutation-observer";
import { useCallback, useRef } from "react";

const useDisableElements = (selectors?: string[]) => {
  const docRef = useRef(document.body);

  const handleMutate = useCallback(
    (mutationList: MutationRecord[]) => {
      if (typeof selectors === "undefined") {
        return;
      }

      mutationList.forEach((mutation) => {
        const { type, attributeName, oldValue, addedNodes } = mutation;
        const wasNodeEnabled =
          type === "attributes" &&
          attributeName === "disabled" &&
          oldValue === "true";
        const wereNodesAdded = type === "childList" && addedNodes.length;
        if (wasNodeEnabled || wereNodesAdded) {
          const matches = docRef.current?.querySelectorAll(selectors.join());

          matches?.forEach((el) => {
            if (!el.getAttribute("disabled")) {
              el.setAttribute("disabled", "true");
            }
          });
        }
      });
    },
    [selectors]
  );

  useMutationObserver(docRef, handleMutate);
};

export default useDisableElements;
