import { Box, Heading, IconButton } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as AuthBackArrowIcon } from "styles/icons/back-arrow-icon.svg";
import AuthSignUpForm from "./AuthSignUpForm";
import { VIEWS } from "./commonTypes";

interface AuthSignUpEmailViewProps {
  setAuthView: (view: VIEWS) => void;
  prefillEmail?: string;
}

const AuthSignUpEmailView = ({
  setAuthView,
  prefillEmail,
}: AuthSignUpEmailViewProps) => {
  return (
    <Box>
      <Box textAlign="center" marginBottom="50px" position="relative">
        <IconButton
          position="absolute"
          left="0px"
          top="50%"
          transform="translateY(-50%)"
          padding="2px"
          width="26px"
          height="20px"
          icon={<AuthBackArrowIcon width="22px" height="16px" />}
          variant="unstyled"
          aria-label="Move back to Sign up options"
          onClick={() => {
            setAuthView("SIGN_UP_OPTIONS_VIEW");
          }}
        />
        <Heading
          as="h3"
          fontFamily="Brandon"
          fontSize="20px"
          lineHeight="27px"
          fontWeight={700}
          color="neutral.700"
          textAlign="center"
        >
          Sign up with email
        </Heading>
      </Box>
      <Box>
        <AuthSignUpForm prefillEmail={prefillEmail} />
      </Box>
    </Box>
  );
};

export default AuthSignUpEmailView;
