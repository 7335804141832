import { Box, BoxProps, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

export type HandleShareClickFnType = (params: {
  url: string;
  label: string;
}) => void;

interface LinkContentProps extends BoxProps {
  icon: React.ReactNode;
  label: string;
  url: string;
  handleShareClick?: HandleShareClickFnType;
}

const LinkContent = ({
  icon,
  label,
  url,
  handleShareClick,
  ...props
}: LinkContentProps) => {
  const handleShareBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (handleShareClick) {
      handleShareClick({ url, label });
    }
  };

  return (
    <Box
      bg="#F1F4F4"
      height="52px"
      minWidth="205px"
      borderRadius="10px"
      onClick={handleShareBtnClick}
      _hover={{ transform: "scale(1.015)", bg: "#EBEBEB" }}
      {...props}
    >
      <Link
        href={url}
        isExternal
        color="black"
        _focus={{
          boxShadow: "none",
        }}
        _hover={{ textDecoration: "none", color: "black" }}
      >
        <Flex align="center" px="13px" py="14px" transition="0.3s">
          <Box mr="11px" height="20px" width="20px" order={[2, 1]}>
            {icon}
          </Box>
          <Text
            fontFamily="Brandon"
            fontSize="16px"
            fontWeight={500}
            order={[1, 2]}
            width="100%"
          >
            {label}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

export default LinkContent;
