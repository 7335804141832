import { AxiosError } from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as Branch from "../branch";
import { getBearerTokenForCurrentUser } from "../firebaseAuthHelpers";

export async function getAuthHeaders() {
  const token = await getBearerTokenForCurrentUser();

  return {
    Authorization: `Bearer ${token}`,
  };
}

export enum BranchIoCampaign {
  WebShare = "web-share",
}

interface BranchIOShareUrlParams {
  weblink: string;
  deeplink: string;
  userId?: string;
  cb?: (link: string) => void;
  campaign?: BranchIoCampaign;
  channel?: string;
  fallbackUrl?: string;
  sendSharerUserId?: boolean;
}

export function getBranchIoShareUrl({
  weblink,
  deeplink,
  campaign = BranchIoCampaign.WebShare,
  userId,
  cb,
  channel,
  fallbackUrl,
  sendSharerUserId = true,
}: BranchIOShareUrlParams) {
  const shareLinkId = uuidv4();

  return Branch.link(
    {
      ...(channel && { channel }),
      campaign,
      data: {
        $deeplink: deeplink,
        $deeplink_v2: deeplink,
        ...(sendSharerUserId && { $sharer_user_id: userId }),
        $share_link_id: shareLinkId,
        $share_created_date: moment().valueOf().toString(),
        $ios_passive_deepview: "false",
        $android_passive_deepview: "false",
        $desktop_url: weblink,
        $fallback_url: fallbackUrl,
      },
    },
    (err, link) => {
      if (cb) {
        cb(!err && link ? link : weblink);
      }
    }
  );
}

export const getApiError = (error: AxiosError) => {
  if (typeof error === "string") {
    return error;
  }

  return error?.response?.data?.message ?? error?.message ?? null;
};
