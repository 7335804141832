import { Icon } from "@chakra-ui/icons";
import { Box, Button, ButtonProps, Spinner, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { User } from "firebase/auth";
import { signInWithFacebook } from "lib/firebaseAuthHelpers";
import fireEvent from "lib/fireEvent";
import React, { ReactElement, useState } from "react";
import { ReactComponent as FacebookIcon } from "styles/icons/login-facebook.svg";

export interface Props extends ButtonProps {
  signup?: boolean;
  disabled?: boolean;
  onSubmit: () => void;
  onComplete: (err?: string, user?: User) => void;
  renderFacebookIcon?: () => ReactElement;
}

const SignInWithFacebook: React.FC<Props> = ({
  signup,
  onSubmit,
  onComplete,
  renderFacebookIcon,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleFacebookSignIn = () => {
    onSubmit();
    setLoading(true);
    signInWithFacebook()
      .then(({ user }) => {
        const firebaseUser = user as User;

        onComplete(undefined, firebaseUser ?? undefined);
        fireEvent("Authenticated");
      })
      .catch((error) => onComplete(error))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      width="100%"
      height={["50px", "50px", "48px"]}
      borderRadius="12px"
      bgColor="neutral.0"
      color="neutral.700"
      border="1px solid var(--chakra-colors-neutral-200)"
      position="relative"
      disabled={loading}
      {...buttonProps}
      onClick={handleFacebookSignIn}
    >
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-38%)"
        left="20px"
      >
        {loading ? (
          <Spinner size="sm" color="inherit" />
        ) : (
          <>
            {renderFacebookIcon ? (
              renderFacebookIcon()
            ) : (
              <Box>
                <Icon
                  as={FacebookIcon}
                  width="20px"
                  height="20px"
                  css={`
                    path {
                      fill: #1877f2;
                    }
                  `}
                />
              </Box>
            )}
          </>
        )}
      </Box>
      <Text
        fontFamily="Brandon"
        fontSize={["18px", "18px", "16px"]}
        lineHeight={["25px", "25px", "23px"]}
        fontWeight={500}
        color="neutral.700"
      >
        {signup ? (
          <Trans>Sign up with Facebook</Trans>
        ) : (
          <Trans>Sign in with Facebook</Trans>
        )}
      </Text>
    </Button>
  );
};

export default SignInWithFacebook;
