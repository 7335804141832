// eslint-disable-next-line no-restricted-imports
import { setupI18n } from "@lingui/core";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import React, { createContext, useContext, useState } from "react";
import de from "../locales/de/messages.js";
import en from "../locales/en/messages.js";
import es from "../locales/es/messages.js";
import fr from "../locales/fr/messages.js";
import ptBR from "../locales/pt-BR/messages.js";
import ruRU from "../locales/ru-RU/messages.js";

const catalogs = {
  en,
  de,
  es,
  fr,
  "pt-BR": ptBR,
  "ru-RU": ruRU,
};

export const defaultLocale = "en";
export const i18n = setupI18n({
  language: defaultLocale,
  catalogs,
});

const I18nContext = createContext({
  setLanguage: (language: string) => {},
});

export const I18nProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState(defaultLocale);
  // We need to call this because we are using:
  // https://lingui.js.org/tutorials/react-patterns.html#translations-outside-react-components
  i18n.activate(language);

  return (
    <I18nContext.Provider value={{ setLanguage }}>
      {/* TODO: Fix and remove this ts-ignore */}
      {/* @ts-ignore */}
      <LinguiI18nProvider language={language} i18n={i18n}>
        {children}
      </LinguiI18nProvider>
    </I18nContext.Provider>
  );
};

export const useI18nContext = () => {
  const context = useContext(I18nContext);

  return context;
};
