import { getApiError } from "lib/services/shared";
import useSWR from "swr";
import { getGlobalStats } from "../lib/services/libraryItems";

export const useGlobalStats = () => {
  const context = useSWR("global_stat", getGlobalStats, {
    revalidateOnFocus: false,
    refreshWhenOffline: false,
  });
  const { data, isValidating, error } = context;
  const isGlobalStatsLoading = !data && isValidating;

  return {
    ...context,
    globalStats: data,
    isGlobalStatsLoading,
    error: getApiError(error),
  };
};
