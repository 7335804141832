import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import ModalCloseButton from "../Modals/ModalCloseButton";
import ShareMenu from "../ShareMenu";
import { ShareItemType } from "../ShareMenu/types";

interface Props {
  open: boolean;
  url: string;
  title: string;
  message?: string;
  shareItemId?: string;
  shareItemType?: ShareItemType;
  shareUserId?: string;
  shareLinkId?: string;
  shareCreatedDate?: string;
  additionalQueryString?: string; // Additional query that pass along with share url like "utm_medium(pass type of the content)"
  onClose: () => void;
}

const ShareModal: React.FC<Props> = ({
  open,
  url,
  title,
  message,
  shareItemId,
  shareItemType,
  shareUserId,
  shareLinkId,
  shareCreatedDate,
  additionalQueryString,
  onClose,
}) => {
  const modalSize = useBreakpointValue({ base: "full", md: "xl" });
  const handleBackdropClose = () => {
    onClose();
  };

  return (
    <Modal
      size={modalSize}
      isOpen={open}
      onClose={handleBackdropClose}
      isCentered={modalSize === "full" ? false : true}
      blockScrollOnMount
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        width={["100%", "484px"]}
        p={["50px 24px", "32px"]}
        borderRadius={["0px", "20px", "20px"]}
        marginY={["0px", "20px"]}
      >
        <ModalCloseButton onClick={onClose} />
        <ModalBody padding="0px">
          <Heading
            as="h2"
            fontFamily="Brandon"
            fontSize="20px"
            lineHeight="26px"
            fontWeight={700}
            color="neutral.700"
            marginBottom="20px"
          >
            Share with your friends and family
          </Heading>
          <ShareMenu
            centered
            url={url || ""}
            title={title}
            showShare={open}
            shareMessage={message}
            shareItemId={shareItemId}
            shareItemType={shareItemType}
            shareUserId={shareUserId}
            shareLinkId={shareLinkId}
            shareCreatedDate={shareCreatedDate}
            additionalQueryString={additionalQueryString}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
