import React, { useContext, useEffect, useState } from "react";
import { AuthState, useFirebaseAuth } from "../hooks/useFirebaseAuth";
import { firestore } from "../lib/firebase";

interface UserSettingDoc {
  is_subscriber?: boolean;
}

interface Context {
  isLoading: boolean;
  isSubscriber: boolean;
  setIsSubscriber: (value: boolean) => void;
}

export const UserSubscriberContext = React.createContext<Context | undefined>(
  undefined
);

export const UserSubscriberProvider: React.FC = ({ children }) => {
  const { user, authState } = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscriber, setIsSubscriber] = useState(false);

  useEffect(() => {
    if (authState === AuthState.AUTHORIZED && user) {
      // Fetch user settings
      firestore
        .doc(`users/${user.id}/private/settings`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data: UserSettingDoc | undefined = doc.data();
            setIsSubscriber(data?.is_subscriber ? data.is_subscriber : false);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (authState === AuthState.UNAUTHORIZED) {
      setIsLoading(false);
    }
  }, [authState, user]);

  return (
    <UserSubscriberContext.Provider
      value={{
        isLoading,
        isSubscriber,
        setIsSubscriber,
      }}
    >
      {children}
    </UserSubscriberContext.Provider>
  );
};

export function useUserSubscriber() {
  const context = useContext(UserSubscriberContext);
  if (!context) {
    throw new Error(
      "useUserSubscriber needs to be used within UserSubscriberProvider"
    );
  }

  return context;
}
