import firebase from "firebase/compat/app";

export interface PaginationConfig {
  startAt: string | number;
  limit: number;
}

export function paginateFirestoreQuery(
  ref: firebase.firestore.Query<firebase.firestore.DocumentData>,
  pagination?: PaginationConfig
) {
  if (pagination) {
    ref = ref.startAfter(pagination.startAt).limit(pagination.limit);
  }
  return ref;
}
