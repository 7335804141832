import { PageTypes } from "contexts/Analytics/types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { useAttendeeInviterContext } from "../contexts/AttendeeInviterContext";
import * as GA from "../lib/analytics/ga";
import * as GTM from "../lib/analytics/gtm";
import * as Branch from "../lib/branch";

const PageViewTrackerContext = React.createContext<
  | {
      pageType?: PageTypes;
      trackPageView: (pageType: PageTypes, shouldSendEvent?: boolean) => void;
    }
  | undefined
>(undefined);

export interface State {
  eventSent: boolean;
  pageType: PageTypes;
}

export type Action =
  | {
      type: "TRACK_PAGE_VIEW";
      pageType: PageTypes;
      shouldSendEvent: boolean;
    }
  | { type: "EVENT_SENT" }
  | { type: "PAGE_CHANGED" };

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case "TRACK_PAGE_VIEW":
      return {
        pageType: action.pageType,
        eventSent: action.shouldSendEvent ? false : true,
      };
    case "EVENT_SENT":
      return {
        ...state,
        eventSent: true,
      };
    case "PAGE_CHANGED":
      return initialState;
    default:
      return state;
  }
}

const initialState: State = {
  eventSent: false,
  pageType: PageTypes.Unknown,
};

export const PageViewTrackerProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { dispatch: dispatchSharer } = useAttendeeInviterContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLandingRef = useRef(true);

  // handle when data is received.
  useEffect(() => {
    const { eventSent, pageType } = state;
    if (!eventSent && pageType) {
      Branch.trackPageView();
      dispatch({ type: "EVENT_SENT" });
    }

    if (isLandingRef.current && pageType) {
      isLandingRef.current = false;
    }
  }, [dispatch, state]);

  // handle page changes.
  useEffect(() => {
    return history.listen((location) => {
      console.info(`page.changed: ${location.pathname}`);
      dispatch({ type: "PAGE_CHANGED" });

      GA.pageView(`${window.location.pathname}${window.location.search}`);

      GTM.reset();
      // Initialize Google Optimize
      GTM.pushDataLayer({
        event: "optimize.activate",
      });
    });
  }, [history]);

  useEffect(() => {
    Branch.data((branchData) => {
      dispatchSharer({
        type: "SET_BRANCH_SHARER_USER_ID",
        branchSharerUserId: Branch.getSharerUserId(branchData),
      });
    });
  }, [dispatchSharer]);

  // create memo-ized callback to prevent unecessary renders.
  const trackPageView = useCallback(
    (pageType: PageTypes, shouldSendEvent = true) => {
      dispatch({
        type: "TRACK_PAGE_VIEW",
        pageType,
        shouldSendEvent: shouldSendEvent,
      });
    },
    [dispatch]
  );

  return (
    <PageViewTrackerContext.Provider
      value={{
        pageType: state.pageType,
        trackPageView,
      }}
    >
      {children}
    </PageViewTrackerContext.Provider>
  );
};

export const usePageViewTracker = () => {
  const context = useContext(PageViewTrackerContext);
  if (!context) {
    throw new Error(
      "PageViewTrackerContext needs to be used within PageViewTrackerProvider"
    );
  }

  return context;
};
