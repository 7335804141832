import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const PrimaryButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      rounded="md"
      color="black"
      fontWeight={600}
      fontSize="15px"
      _hover={{ bg: "white" }}
      {...props}
    />
  );
};

export default PrimaryButton;
