import React, { createContext, useContext, useEffect, useReducer } from "react";
import { getEventSharerUserId, saveEventSharerUserId } from "../lib/branch";
import { UserSummary } from "../lib/services/users";
import { getUserProfile, isGuestUser, isTeacher } from "../lib/userRepository";

interface IAttendeeInviterReducerState {
  branchSharerUserId?: string;
  eventId?: string;
  inviterUserId?: string | null;
  inviterUser?: UserSummary;
  inviterDrawerIsOpen: boolean;
  loadingInviterUser: boolean;
  loadedBranchSharerUserId: boolean;
  loadedEventId: boolean;
}

interface IAttendeeInviterContext extends IAttendeeInviterReducerState {
  loadedInviter: boolean;
  loadingInviterUser: boolean;
  dispatch: React.Dispatch<IAttendeeInviterReducerAction>;
}

type IAttendeeInviterReducerAction =
  | { type: "SET_BRANCH_SHARER_USER_ID"; branchSharerUserId?: string }
  | {
      type: "SET_SHARED_EVENT_ID";
      eventId: string;
    }
  | {
      type: "SAVE_EVENT_SHARER_USER_ID";
      eventId: string;
      branchSharerUserId: string;
    }
  | {
      type: "LOAD_CACHED_EVENT_SHARER_ID";
      eventId: string;
    }
  | {
      type: "LOAD_INVITER_USER";
    }
  | {
      type: "LOAD_INVITER_USER_SUCCESS";
      inviterUser: UserSummary;
    }
  | {
      type: "OPEN_INVITER_USER_DRAWER";
    }
  | {
      type: "CLOSE_INVITER_USER_DRAWER";
    };

const initialState: IAttendeeInviterReducerState = {
  branchSharerUserId: undefined,
  inviterUserId: undefined,
  inviterDrawerIsOpen: false,
  loadingInviterUser: false,
  inviterUser: undefined,
  eventId: undefined,
  loadedBranchSharerUserId: false,
  loadedEventId: false,
};

const attendeeInviterReducer = (
  state: IAttendeeInviterReducerState,
  action: IAttendeeInviterReducerAction
): IAttendeeInviterReducerState => {
  switch (action.type) {
    case "SET_BRANCH_SHARER_USER_ID":
      return {
        ...state,
        loadedBranchSharerUserId: true,
        branchSharerUserId: action.branchSharerUserId,
      };
    case "SET_SHARED_EVENT_ID":
      return {
        ...state,
        loadedEventId: true,
        eventId: action.eventId,
      };
    case "SAVE_EVENT_SHARER_USER_ID":
      saveEventSharerUserId(action.eventId, action.branchSharerUserId);
      return {
        ...state,
        inviterUserId: action.branchSharerUserId,
      };
    case "LOAD_CACHED_EVENT_SHARER_ID":
      const eventId = state.eventId;
      if (eventId != null) {
        const cachedEventSharerId = getEventSharerUserId(eventId);
        return {
          ...state,
          inviterUserId: cachedEventSharerId,
        };
      }
      return state;
    case "LOAD_INVITER_USER":
      return {
        ...state,
        loadingInviterUser: true,
      };
    case "LOAD_INVITER_USER_SUCCESS":
      return {
        ...state,
        loadingInviterUser: false,
        inviterUser: action.inviterUser,
      };
    case "OPEN_INVITER_USER_DRAWER":
      return {
        ...state,
        inviterDrawerIsOpen: true,
      };
    case "CLOSE_INVITER_USER_DRAWER":
      return {
        ...state,
        inviterDrawerIsOpen: false,
      };

    default: {
      return state;
    }
  }
};

const AttendeeInviterContext = createContext<IAttendeeInviterContext>({
  ...initialState,
  loadingInviterUser: false,
  loadedInviter: false,
  dispatch: () => {},
});

export const AttendeeInviterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(attendeeInviterReducer, initialState);
  const {
    loadedBranchSharerUserId,
    loadedEventId,
    branchSharerUserId,
    eventId,
    inviterUserId,
  } = state;

  // Load sharer user id from localStorage
  useEffect(() => {
    if (eventId != null) {
      dispatch({
        type: "LOAD_CACHED_EVENT_SHARER_ID",
        eventId,
      });
    }
  }, [eventId, dispatch]);

  // Save sharer user id from Branch payload
  useEffect(() => {
    if (branchSharerUserId != null && eventId != null) {
      dispatch({
        type: "SAVE_EVENT_SHARER_USER_ID",
        eventId,
        branchSharerUserId,
      });
    }
  }, [eventId, branchSharerUserId, dispatch]);

  // Load user profile from API when inviterUserid is available
  useEffect(() => {
    async function loadInviter() {
      if (inviterUserId != null) {
        dispatch({
          type: "LOAD_INVITER_USER",
        });
        const user = await getUserProfile(inviterUserId);

        // Ignore invites from users w/o a profile
        if (!user) return;

        // Ignore invites from guest users
        if (isGuestUser(user)) return;

        // Ignore invites from teachers
        if (isTeacher(user)) return;

        dispatch({
          type: "LOAD_INVITER_USER_SUCCESS",
          inviterUser: user,
        });
      }
    }

    loadInviter();
  }, [inviterUserId]);

  return (
    <AttendeeInviterContext.Provider
      value={{
        ...state,
        loadedInviter: loadedBranchSharerUserId && loadedEventId,
        dispatch,
      }}
    >
      {children}
    </AttendeeInviterContext.Provider>
  );
};

export const useAttendeeInviterContext = () =>
  useContext(AttendeeInviterContext);
