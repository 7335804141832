import numeral from "numeral";

/**
 * `(1000) => returns "1k"`,
 * `(1100) => returns "1.1k"`,
 * `(1) => returns "1"`,
 * `(1000000) => returns "1m"`,
 * `(1110000) => returns "1.1m"`
 * @param count
 */
export const formatCount = (count: number) => {
  const formattedCount = numeral(count).format("0.0a");
  return formattedCount.replace(".0", "");
};

export const formatCentsAsUSD = (cents: number) =>
  (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  /**
 * convert number into million, billion and in k's
 */
export const numberConvert = (
  labelValue: string | number,
  full = false,
  toFixedValue = 1 // Define how many digits you want to show after decimal point.
) => {
  if (full) {
    // Nine Zeroes for Billions
    const number =
      Math.abs(Number(labelValue)) >= 1.0e9
        ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(
            toFixedValue
          )} billion`
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(
            toFixedValue
          )} million`
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 100.0e3
        ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(toFixedValue)}k`
        : Math.abs(Number(labelValue));

    return number;
  }
  // Nine Zeroes for Billions
  const number =
    Math.abs(Number(labelValue)) >= 1.0e9
      ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(toFixedValue)}b`
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(toFixedValue)}m`
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(toFixedValue)}k`
      : Math.abs(Number(labelValue));

  return number;
};
