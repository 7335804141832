import { FACBOOK_ID } from "../../../constants/sharedConstants";

interface GetContentShareUrlsParams {
  shareUrl: string;
  shareTitle: string;
  shareMessage?: string;
  queryString?: string; // extra querystring that pass along with share link like what is the type of content(utm_medium: content, live_stream_share, etc.).
}

const getContentShareUrls = ({
  shareUrl,
  shareTitle,
  shareMessage,
  queryString = "utm_medium=content",
}: GetContentShareUrlsParams) => {
  const extraQueryString = queryString ? `&${queryString}` : "";
  const encodedShareMessage =
    (shareMessage && encodeURIComponent(shareMessage)) ??
    "Check this from Insight Timer";
  const encodedTitle = encodeURIComponent(shareTitle);

  return {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}?utm_source=facebook${extraQueryString}`,
    facebookMessenger: `https://www.facebook.com/dialog/send?app_id=${FACBOOK_ID}&redirect_uri=${shareUrl}&link=${shareUrl}?utm_source=messenger${extraQueryString}`,
    whatsApp: `https://api.whatsapp.com/send?text=${`${encodedTitle} ${encodedShareMessage} ${shareUrl}`}&utm_source=whatsapp${extraQueryString}`,
    twitter: `https://twitter.com/share?text=${encodedTitle}&url=${shareUrl}&utm_source=twitter${extraQueryString}`,
    linkedIn: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&utm_source=linkedin${extraQueryString}`,
    email: `mailto:?to=&subject=${encodedTitle}&body=${`${encodedShareMessage} ${shareUrl}`}&utm_source=email${extraQueryString}`,
    sms: `sms:?&body=${`${encodedTitle} ${encodedShareMessage} ${shareUrl}`}?utm_source=sms${extraQueryString}`,
    clipboardUrl: `${shareUrl}?utm_source=copy_link${extraQueryString}`,
  };
};

export default getContentShareUrls;
