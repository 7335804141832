import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import PrimaryButton from "../../components/shared/Buttons/PrimaryButton";
import Message from "./Message";

interface IErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;
  color: string;
  hasCloseButton: boolean;
}

const ErrorModal = ({
  isOpen,
  onClose,
  title,
  body,
  color,
  hasCloseButton,
}: IErrorModalProps) => (
  <Modal
    size="lg"
    isCentered
    isOpen={isOpen}
    onClose={onClose}
    closeOnOverlayClick={false}
  >
    <ModalOverlay />
    <ModalContent alignSelf="center" rounded="lg">
      {hasCloseButton && <ModalCloseButton />}
      <ModalBody color="#222222">
        <Message title={title} body={body} color={color} />
      </ModalBody>
      {hasCloseButton && (
        <ModalFooter px={2} py={0}>
          <PrimaryButton
            backgroundColor="black"
            color="white"
            onClick={onClose}
            _hover={{}}
            m={4}
            type="submit"
            flex={1}
          >
            Close
          </PrimaryButton>
        </ModalFooter>
      )}
    </ModalContent>
  </Modal>
);

export default ErrorModal;
