import {
  removeSessionCookies,
  signInWithSessionCookie,
} from "lib/firebaseAuthHelpers";
import getCookie from "lib/getCookie";
import { useEffect, useState } from "react";
import { AuthState, useFirebaseAuth } from "../hooks/useFirebaseAuth";

export function useSessionAuth() {
  const { authState, logoutInProgress } = useFirebaseAuth();
  const [hasError, setHasError] = useState(false);

  const firebaseSessionCookie = getCookie("__session");
  const hasSessionCookie = getCookie("hasSessionCookie");

  /**
   * IF there is a session cookie (this is set from main website), then attempt to login with it.
   */
  useEffect(() => {
    setHasError(false);

    if (logoutInProgress) {
      return;
    }

    if (
      authState === AuthState.UNAUTHORIZED &&
      (firebaseSessionCookie || hasSessionCookie)
    ) {
      signInWithSessionCookie().catch(async (err) => {
        console.warn(err);
        setHasError(true);
        // if this fails, we will remove the cookies - or it will go into an infinite loop.
        removeSessionCookies();
      });
    }
  }, [authState, firebaseSessionCookie, hasSessionCookie, logoutInProgress]);

  return {
    isCheckingSessionCookie:
      authState === AuthState.UNAUTHORIZED &&
      !hasError &&
      (firebaseSessionCookie || hasSessionCookie),
  };
}
