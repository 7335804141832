import AuthModal, {
  ModalCloseCallbackFnTypeParams,
  RefDetailsSchema,
} from "components/shared/AuthModal";
import { UserSummary } from "lib/services/users";
import noop from "lodash/noop";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthState, useFirebaseAuth } from "./useFirebaseAuth";

interface SuccessCallbackParams {
  user: UserSummary;
}

type SuccessCallbackType = (params?: SuccessCallbackParams) => void;

interface PopupActionCommonParams {
  successCallback: SuccessCallbackType;
  avoidDialogClose?: boolean;
}

interface ShowSignupPopupFnParams extends PopupActionCommonParams {
  email?: string;
}

interface AuthPopupContextInterface {
  showLoginPopup: (params?: PopupActionCommonParams) => void;
  showSignupPopup: (params?: ShowSignupPopupFnParams) => void;
}

export const AuthPopupContext = React.createContext<AuthPopupContextInterface>({
  showLoginPopup: noop,
  showSignupPopup: noop,
});

export const AuthPopupProvider: React.FC = ({ children }) => {
  const { authState, user } = useFirebaseAuth();
  const authModalDetailsRef = useRef<RefDetailsSchema | undefined>(undefined);
  const [successCallback, setSuccessCallback] = useState<SuccessCallbackType>();
  const [avoidDialogClose, setAvoidDialogClose] = useState(false);

  const displayLoginPopup = useCallback((params?: PopupActionCommonParams) => {
    if (params?.successCallback) {
      setSuccessCallback(() => {
        return params?.successCallback;
      });
    }

    if (params?.avoidDialogClose) {
      setAvoidDialogClose(params.avoidDialogClose);
    }

    if (authModalDetailsRef.current) {
      authModalDetailsRef.current.setView("LOGIN");
      authModalDetailsRef.current.openModal();
    }
  }, []);

  const displaySignupPopup = useCallback((params?: ShowSignupPopupFnParams) => {
    if (params?.successCallback) {
      setSuccessCallback(() => {
        return params?.successCallback;
      });
    }

    if (params?.avoidDialogClose) {
      setAvoidDialogClose(params.avoidDialogClose);
    }

    if (authModalDetailsRef.current) {
      authModalDetailsRef.current.setView("SIGN_UP_OPTIONS_VIEW");
      authModalDetailsRef.current.openModal({ email: params?.email });
    }
  }, []);

  useEffect(() => {
    if (authState === AuthState.AUTHORIZED && successCallback && user) {
      successCallback({ user });
      setSuccessCallback(undefined);
    }
  }, [authState, successCallback, user]);

  const modalCloseCallback = useCallback(
    ({ ignoreResetSuccessCallback }: ModalCloseCallbackFnTypeParams) => {
      if (!ignoreResetSuccessCallback) {
        setSuccessCallback(undefined);
      }

      setAvoidDialogClose(false);
    },
    []
  );

  return (
    <AuthPopupContext.Provider
      value={{
        showLoginPopup: displayLoginPopup,
        showSignupPopup: displaySignupPopup,
      }}
    >
      <AuthModal
        modalDetailsRef={authModalDetailsRef}
        avoidDialogClose={avoidDialogClose}
        onCloseCallback={modalCloseCallback}
      />
      {children}
    </AuthPopupContext.Provider>
  );
};

export const useAuthPopup = () => {
  const context = useContext(AuthPopupContext);

  if (!context) {
    throw new Error("useAuthPopup must use withinh AuthPopupProvider.");
  }

  return context;
};
