// FIXME: [WEB-896] Refactor and rename to user
import { firestore } from "../../firebase";
import {
  docRefToArray,
  docToObject,
  getCurrentUserUid,
} from "../../firebaseHelpers";
import { rollbar } from "../../rollbar";
import { DateTime } from "../sharedInterfaces";

interface UserSettings {
  number_of_friends: number;
  insight_admin_permissions?: {
    has_insight_admin_permissions?: boolean;
    is_editor?: boolean;
    is_super_admin?: boolean;
    is_viewer?: boolean;
  };
}

export interface PrivateSettingsEmailAddressDoc {
  id: string;
  created_at: DateTime;
  email_id: string;
  email_provider: string;
  email_triggered_at: DateTime;
  email_verification_type: "LINK" | "4_DIGIT_CODE";
  expired_at: DateTime;
  is_deleted: boolean;
  is_verified: boolean;
  updated_at: DateTime;
  verification_code: string;
}

export const getUserPrivateSettings = async () => {
  const uid = getCurrentUserUid();

  return firestore
    .doc(`/users/${uid}/private/settings`)
    .get()
    .then((doc) => {
      return docToObject<UserSettings>(doc);
    });
};

export const getUserNotificationLastSeen = async (uid: string) => {
  return firestore
    .doc(`/users/${uid}/private/settings`)
    .get()
    .then((doc) => {
      const data = doc.data();
      return data?.group_notifications_last_seen_time;
    });
};

export const setUserPrivateSettingsValue = async (values: {
  [key: string]: any;
}) => {
  const uid = getCurrentUserUid();
  return firestore
    .doc(`/users/${uid}/private/settings`)
    .set(values, {
      merge: true,
    })
    .catch((error) => {
      console.error("Failed to set user private settings values:", values);
      throw error;
    });
};

export const setUserNotificationLastSeen = async (
  uid: string,
  epoch: number,
  ISODateTime: string
) => {
  return firestore
    .doc(`/users/${uid}/private/settings`)
    .set(
      {
        group_notifications_last_seen_time: {
          epoch: epoch,
          iso_8601_datetime_tz: ISODateTime,
        },
      },
      { merge: true }
    )
    .catch((error) => {
      console.error(
        "userSettings: failed to write user notification last seen",
        error
      );
    });
};

export const setUserGroupRelationConversationLastSeen = async (
  uid: string,
  groupId: string,
  epoch: number,
  ISODateTime: string
) => {
  return firestore
    .doc(`/users/${uid}/group_relation/${groupId}`)
    .set(
      {
        group_conversation_last_seen_time: {
          epoch: epoch,
          iso_8601_datetime_tz: ISODateTime,
        },
      },
      { merge: true }
    )
    .catch((error) => {
      rollbar.error(
        `user.group_relations.${groupId}: failed to write group_conversation_last_seen`,
        error
      );
    });
};

export const subscribeUserEmailVerification = (
  uid: string,
  emailToVerify: string,
  onSnapshot: (
    emailVerificationDoc: PrivateSettingsEmailAddressDoc | null | undefined
  ) => void
) => {
  return firestore
    .collection(`users/${uid}/private/settings/email_addresses`)
    .where("email_id", "==", emailToVerify)
    .limit(1)
    .onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
      // Even though we limit(1), the "where" clause returns a collection.
      const docs = docRefToArray<PrivateSettingsEmailAddressDoc>(snapshot);
      docs.length > 0 ? onSnapshot(docs[0]) : onSnapshot(undefined);
    });
};

/**
 * Used to get verified emails of given user.
 **/
export const getUserVerifiedEmailAddresses = (uid: string) => {
  return firestore
    .collection(`users/${uid}/private/settings/email_addresses`)
    .where("is_verified", "==", true)
    .where("is_deleted", "==", false)
    .get()
    .then((docRef) => {
      if (!docRef.docs) {
        return null;
      }

      const list = docRef.docs.map((doc) => {
        if (!doc.exists) {
          return null;
        }

        const userEmailDetails = doc.data();

        return {
          id: doc.id,
          ...userEmailDetails,
        } as PrivateSettingsEmailAddressDoc;
      });
      const filteredEmailList = list.filter(
        (emailDetail) => emailDetail !== null
      ) as PrivateSettingsEmailAddressDoc[];

      return filteredEmailList;
    });
};
