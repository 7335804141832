/** This hook observes the DOM for additions/removals of a step's element.
 *  We use a MutationObserver to check all DOM changes, which is useful
 *  when the observer is not in the same tree and renders independently.
 *  It returns the element when in the DOM, or null when not.
 */
import useMutationObserver from "@rooks/use-mutation-observer";
import { useCallback, useEffect, useRef, useState } from "react";

const isElement = (node: Node | null): node is Element =>
  (node as Element)?.nodeType !== undefined &&
  (node as Element)?.attributes !== undefined;

// Recursively checks a NodeList for any children that contain a `data-tour-step` attribute
const containsTourNode = (nodes: NodeList, tourStep: string): boolean => {
  let wasTourStepFound = false;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes.item(i);

    if (!isElement(node)) {
      return false;
    }

    const dataTourStepAttr =
      node.attributes?.length > 0 &&
      node.attributes.getNamedItem("data-tour-step");

    if (node && dataTourStepAttr) {
      wasTourStepFound = dataTourStepAttr.value === tourStep;
      break;
    }

    if (node.childNodes.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      wasTourStepFound = containsTourNode(node.childNodes, tourStep);
    }
    if (wasTourStepFound) {
      break;
    }
  }

  return wasTourStepFound;
};

const getStepElement = (tourStep: string) =>
  document.querySelector(`[data-tour-step='${tourStep}']`);

interface IHookOptions {
  onClick?: (e: Event) => void;
}

const useTourElement = (tourStep: string, options: IHookOptions = {}) => {
  const docRef = useRef(document.body);
  const [element, setElement] = useState<Element | null>(
    getStepElement(tourStep)
  );

  // Handle edge case: step change without DOM mutating
  useEffect(() => {
    setElement(getStepElement(tourStep));
  }, [tourStep]);

  const handleMutate = useCallback(
    (mutationList: MutationRecord[]) => {
      mutationList.forEach(
        ({ addedNodes, removedNodes, type, target, attributeName }) => {
          // Handle tour step attr being added or removed from existing node
          // We have to check entire DOM again for desired step, because
          // it isn't possible to tell removals apart.
          if (type === "attributes" && attributeName === "data-tour-step") {
            setElement(getStepElement(tourStep));
          }
          // Handle tour step node being added to DOM
          if (addedNodes.length && containsTourNode(addedNodes, tourStep)) {
            setElement(getStepElement(tourStep));
          }
          // Handle tour step node being removed from DOM
          if (removedNodes.length && containsTourNode(removedNodes, tourStep)) {
            setElement(null);
          }
          return;
        }
      );
    },
    [tourStep]
  );
  useMutationObserver(docRef, handleMutate);

  // Attach click handler if provided
  useEffect(() => {
    if (element && options.onClick) {
      element.addEventListener("click", options.onClick);
    }

    return () => {
      if (element && options.onClick) {
        element?.removeEventListener("click", options.onClick);
      }
    };
  }, [element, options.onClick]);

  return element;
};

export default useTourElement;
