import { default as axios, default as Axios } from "axios";
import firebase from "firebase/compat/app";
import queryString from "query-string";
import { auth, firestore } from "../../firebase";
import {
  docRefToArray,
  docToObject,
  getCurrentUserUid,
  getTimestamp,
} from "../../firebaseHelpers";
import { rollbar } from "../../rollbar";
import { paginateFirestoreQuery } from "../firestore/helpers";
import { Filter } from "../sharedInterfaces";

interface DateTime {
  epoch: number;
}

export interface LibraryItemRelation {
  id: string;
  synced_at: DateTime;
  library_item_summary: LibraryItemSummary;
}

export interface LibraryItemPublisher {
  id: string;
  name: string;
  number_of_followers: number;
  username?: string;
  has_avatar?: boolean;
}

export type ContentType = "GUIDED" | "MUSIC" | "TALKS" | "YOGA";

export interface LibraryItemSummary {
  id: string;
  content_type: ContentType;
  item_type: string;
  title: string;
  rating_score?: number;
  slug?: string;
  publisher?: LibraryItemPublisher;
  media_length?: number;
  web_url?: string;
  media_paths?: string[];
  is_premium?: boolean;
  insight_url?: string;
}

export interface COURSE_DAYS {
  contributors: LibraryItemPublisher[];
  day: number;
  description: string;
  id: string;
  media_paths: string[];
  outline: string;
  title: string;
}

export interface Language {
  iso_639_1: string;
  name: string;
}

export interface LibraryItemRelationBookmark extends LibraryItemRelation {
  is_bookmarked: boolean;
  bookmarked_at: DateTime;
}
export interface LibraryItemRelationCourse extends LibraryItemRelation {
  course_added_at: DateTime;
}
export interface LibraryItemRelationGM extends LibraryItemRelation {
  last_played_at: DateTime;
}

export async function getUserLibraryItemBookmarks(
  limit: number,
  start?: string | number
) {
  const uid = getCurrentUserUid();
  let libItemRelationRef = firestore
    .collection(`/users/${uid}/library_item_relation`)
    .where("library_item_summary.item_type", "in", ["SINGLE_TRACKS", "COURSES"])
    .where("is_bookmarked", "==", true)
    .orderBy("bookmarked_at.epoch", "desc");

  if (start) {
    libItemRelationRef = paginateFirestoreQuery(libItemRelationRef, {
      limit: limit,
      startAt: start,
    });
  } else {
    libItemRelationRef = libItemRelationRef.limit(limit);
  }

  return libItemRelationRef.get().then((ref) => {
    return docRefToArray<LibraryItemRelationBookmark>(ref);
  });
}

export async function getUserLibraryItemBookmark(
  libraryItem: LibraryItemSummary
) {
  const uid = getCurrentUserUid();
  return firestore
    .doc(`/users/${uid}/library_item_relation/${libraryItem.id}`)
    .get()
    .then((ref) => {
      const libraryItemObj =
        docToObject<Pick<LibraryItemRelationBookmark, "id" | "is_bookmarked">>(
          ref
        );
      if (libraryItemObj?.is_bookmarked) {
        return libraryItemObj;
      }
      return undefined;
    });
}

export async function getUserLibraryItemRecent(
  limit: number,
  start?: string | number
) {
  const uid = getCurrentUserUid();
  let libItemRelationRef = firestore
    .collection(`/users/${uid}/library_item_relation`)
    .where("last_played_at.epoch", ">", 0)
    .orderBy("last_played_at.epoch", "desc");

  if (start) {
    libItemRelationRef = paginateFirestoreQuery(libItemRelationRef, {
      limit: limit,
      startAt: start,
    });
  } else {
    libItemRelationRef = libItemRelationRef.limit(limit);
  }

  return libItemRelationRef.get().then((ref) => {
    return docRefToArray<LibraryItemRelationGM>(ref);
  });
}

export async function getUserLibraryItemCourses(
  limit: number,
  start?: string | number
) {
  const uid = getCurrentUserUid();
  let libItemRelationRef = firestore
    .collection(`/users/${uid}/library_item_relation`)
    .where("library_item_summary.item_type", "==", "COURSES")
    .orderBy("last_played_at.epoch", "desc");

  if (start) {
    libItemRelationRef = paginateFirestoreQuery(libItemRelationRef, {
      limit: limit,
      startAt: start,
    });
  } else {
    libItemRelationRef = libItemRelationRef.limit(limit);
  }

  return libItemRelationRef.get().then((ref) => {
    return docRefToArray<LibraryItemRelationCourse>(ref);
  });
}

export interface LibraryItemSummaryDetailed extends LibraryItemSummary {
  short_description: string;
}

export async function getLibraryItemSummary(id: string) {
  const response = await axios.get<LibraryItemSummaryDetailed>(
    `${process.env.REACT_APP_LIBRARY_ITEMS_URL}/${id}/data/libraryitem.json`
  );
  return response.data;
}

export async function getLibraryItemDetails(id: string) {
  if (!id) {
    return null;
  }

  const { data } = await axios.get<LibraryItemSummary>(
    `${process.env.REACT_APP_LIBRARY_ITEMS_URL}/${id}/data/libraryitem.json`
  );

  return data;
}

export async function addBookmark(libraryItem: LibraryItemSummary) {
  const uid = auth.currentUser?.uid;

  if (uid == null) {
    rollbar.error("addBookmark: Trying to add bookmark without currentUser");
    return false;
  }

  const timestamp = getTimestamp();
  return firestore
    .doc(`/users/${uid}/library_item_relation/${libraryItem.id}`)
    .set(
      {
        is_bookmarked: true,
        bookmarked_at: timestamp,
        updated_at: timestamp,
        synced_at: firebase.firestore.FieldValue.serverTimestamp(),
        library_item_summary: libraryItem,
      },
      { merge: true }
    );
}

export async function removeBookmark(libraryItem: LibraryItemSummary) {
  const uid = auth.currentUser?.uid;

  if (uid == null) {
    rollbar.error(
      "removeBookmark: Trying to remove bookmark without currentUser"
    );
    return false;
  }

  const timestamp = getTimestamp();
  return firestore
    .doc(`/users/${uid}/library_item_relation/${libraryItem.id}`)
    .set(
      {
        is_bookmarked: false,
        updated_at: timestamp,
        synced_at: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
}

export type GlobalStats = {
  free_guided_meditations?: number;
  total_users?: number;
};

export function getGlobalStats(): Promise<GlobalStats> {
  return Axios.get<GlobalStats>(
    `https://stats.insighttimer-api.net/admin/data/global_stats.json`
  ).then((resp) => {
    return resp.data;
  });
}

export interface LibraryItemFilterResponse {
  item_summary: {
    library_item_summary: LibraryItemSummary;
  };
}

export const getFilteredLibraryItems = async (
  filter?: Filter
): Promise<{
  result?: LibraryItemSummary[];
  totalCount: number;
}> => {
  const query = filter
    ? queryString.stringify(filter, {
        arrayFormat: "comma",
      })
    : "";
  const response = await Axios.get(
    `${process.env.REACT_APP_FILTERING_URL}/api/v1/single-tracks/filter?${query}`
  );
  const { data } = response;
  const result = data?.map((r: LibraryItemFilterResponse) => {
    return r.item_summary.library_item_summary;
  });

  return {
    result,
    totalCount: response.headers["x-total-count"],
  };
};
