import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import React, { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ReactComponent as MoreHorizIcon } from "../../../styles/icons/more-icon.svg";
import { ReactComponent as Email } from "../../../styles/icons/sharing/email-icon.svg";
import { ReactComponent as Facebook } from "../../../styles/icons/sharing/facebook-icon.svg";
import { ReactComponent as FacebookMessengerIcon } from "../../../styles/icons/sharing/facebook-messenger.svg";
import { ReactComponent as Copylink } from "../../../styles/icons/sharing/link.svg";
import { ReactComponent as Linkedin } from "../../../styles/icons/sharing/linkedin-icon.svg";
import { ReactComponent as Sms } from "../../../styles/icons/sharing/sms.svg";
import { ReactComponent as Twitter } from "../../../styles/icons/sharing/twitter-icon.svg";
import { ReactComponent as Whatsapp } from "../../../styles/icons/sharing/whatsapp.svg";
import getContentShareUrls from "./getContentShareUrls";
import LinkContent from "./LinkContent";
import { ShareChannelType, ShareItemType } from "./types";
import useShareContentEventTracking from "./useShareContentEventTracking";

interface HandleShareClickParams {
  shareChannel: ShareChannelType;
  closePopup?: boolean;
}

interface TProps {
  url: string; // Share link
  title: string;
  showShare: boolean;
  onShareSelect?: () => void;
  onClose?: () => void;
  centered?: boolean;
  shareMessage?: string;
  additionalQueryString?: string; // extra query parameters that will send along with share link
  deepLinkType?: "branch";
  shareItemId?: string;
  shareItemType?: ShareItemType;
  shareUserId?: string;
  shareLinkId?: string;
  shareCreatedDate?: string;
}

const ShareMenu: FC<TProps> = (props) => {
  const {
    url,
    title,
    shareMessage,
    additionalQueryString = "utm_medium=live_stream_share",
    deepLinkType = "branch",
    shareItemId,
    shareItemType,
    shareUserId,
    shareLinkId,
    shareCreatedDate,
  } = props;
  const targetUrl = url ?? "";
  const targetTitle = title ?? "";
  const [showShare, setShowShare] = useState(props.showShare);
  const sharingUrls = getContentShareUrls({
    shareUrl: targetUrl,
    shareTitle: targetTitle,
    shareMessage,
    queryString: additionalQueryString,
  });
  const { onCopy, hasCopied } = useClipboard(sharingUrls.clipboardUrl);
  const { shareContentTracking } = useShareContentEventTracking();

  const handleMoreLink = async () => {
    const nav: Navigator = window.navigator;

    if (nav?.share) {
      nav.share({
        title: targetUrl,
        url: targetUrl,
      });

      handleShareClick({ shareChannel: "more" });
    }
  };

  useEffect(() => {
    setShowShare(props.showShare);
  }, [props.showShare]);

  const handleClipboard = () => {
    onCopy();

    handleShareClick({
      shareChannel: "link",
      closePopup: false,
    });

    if (props.onShareSelect) {
      props.onShareSelect();
    }
  };

  const handleShareClick = ({
    shareChannel,
    closePopup = true,
  }: HandleShareClickParams) => {
    if (shareItemId && shareItemType) {
      shareContentTracking({
        deep_link_type: deepLinkType ?? "branch",
        share_link_id: shareLinkId,
        share_channel: shareChannel,
        invite_link: targetUrl,
        item_id: shareItemId,
        tracking_object_type: shareItemType,
        share_created_date: shareCreatedDate
          ? Number(shareCreatedDate)
          : undefined,
        sharer_user_id: shareUserId,
      });
    }

    if (props.onClose && closePopup) {
      props.onClose();
    }
  };

  return (
    <>
      {showShare === true && (
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={["12px", "12px", "10px"]}>
          <LinkContent
            icon={<Facebook />}
            label="Facebook"
            url={sharingUrls.facebook}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "facebook" });
            }}
          />
          <LinkContent
            icon={<FacebookMessengerIcon />}
            label="Messenger"
            url={sharingUrls.facebookMessenger}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "facebookMessenger" });
            }}
          />
          <LinkContent
            icon={<Whatsapp />}
            label="Whatsapp"
            url={sharingUrls.whatsApp}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "whatsapp" });
            }}
          />
          <LinkContent
            icon={<Twitter />}
            label="Twitter"
            url={sharingUrls.twitter}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "twitter" });
            }}
          />

          <LinkContent
            icon={<Linkedin />}
            label="Linkedin"
            url={sharingUrls.linkedIn}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "linkedin" });
            }}
          />
          <LinkContent
            icon={<Email />}
            label="Email"
            url={sharingUrls.email}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "email" });
            }}
          />
          <LinkContent
            icon={<Sms />}
            label="SMS"
            url={sharingUrls.sms}
            handleShareClick={() => {
              handleShareClick({ shareChannel: "message" });
            }}
          />
          <Box
            bg="#F1F4F4"
            height="52px"
            minWidth="205px"
            borderRadius="10px"
            cursor="pointer"
            _hover={{ transform: "scale(1.015)", bg: "#EBEBEB" }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              handleClipboard();
            }}
          >
            <Flex align="center" px="13px" py="14px" transition="0.3s">
              <Box mr="11px" pl="2px" order={[2, 1]}>
                <Copylink width="17px" height="17px" />
              </Box>
              <Text
                fontSize="16px"
                fontWeight={500}
                width="100%"
                order={[1, 2]}
                color={hasCopied ? "green" : "black"}
              >
                {hasCopied ? (
                  <Trans>Link Copied</Trans>
                ) : (
                  <Trans>Copy Link</Trans>
                )}
              </Text>
            </Flex>
          </Box>
          {isMobile && (
            <Box
              bg="#F1F4F4"
              height="52px"
              minWidth="205px"
              borderRadius="10px"
              onClick={(e) => {
                e.preventDefault();
                handleMoreLink();
              }}
              _hover={{ transform: "scale(1.015)", bg: "#EBEBEB" }}
            >
              <Flex align="center" px="13px" py="14px" transition="0.3s">
                <Box mr="11px" height="20px" width="20px" order={[2, 1]}>
                  <Icon as={MoreHorizIcon} />
                </Box>
                <Text
                  fontSize="16px"
                  fontWeight={500}
                  width="100%"
                  order={[1, 2]}
                >
                  More
                </Text>
              </Flex>
            </Box>
          )}
        </SimpleGrid>
      )}
    </>
  );
};

export default ShareMenu;
