import {
  IToast,
  useDisclosure,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import noop from "lodash/noop";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ToastCard from "../../components/Events/Cards/ToastCard";
import theme from "../../theme";
import ErrorModal from "./ErrorModal";

interface IUseShowConfig extends IToast {
  title?: string;
  color?: string;
  body?: string;
  card?: typeof ToastCard;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

type IUseShowParams = (config?: IUseShowConfig) => string | number | undefined;
type IUseShowModalParams = (config?: IUseShowConfig) => void;

interface IUseShow {
  success: IUseShowParams;
  info: IUseShowParams;
  warning: IUseShowParams;
  error: IUseShowModalParams;
  // TODO: handle custom toast/dialog content
}

const ShowContext = createContext({
  showModal: (config: IUseShowConfig) => {},
});

export const ShowProvider: React.FC = ({ children }) => {
  const errorModal = useDisclosure();
  const { onOpen, onClose } = errorModal;
  const [config, setConfig] = useState<IUseShowConfig>();

  useEffect(() => {
    if (config) {
      onOpen();
    }
  }, [config, onOpen]);

  const customOnClose = () => {
    onClose();
    config?.onClose?.call(this);
  };

  const errorModalProps = {
    ...errorModal,
    title: config?.title || "ERROR",
    body: config?.body || "",
    color: config?.color || "#000",
    hasCloseButton: config?.hasCloseButton || false,
    onClose: customOnClose || noop,
  };

  const showModal = useCallback((messageConfig: IUseShowConfig) => {
    setConfig(messageConfig);
  }, []);

  return (
    <ShowContext.Provider value={{ showModal }}>
      {children}
      <ErrorModal {...errorModalProps} />
    </ShowContext.Provider>
  );
};

const useShow = (): IUseShow => {
  const { showModal } = useContext(ShowContext);
  const toast = useToast();

  const showToast = useCallback(
    (config: IUseShowConfig) => {
      const toastContent = {
        hasCloseButton: config.hasCloseButton || false,
        title: config.title || "",
        body: config.body || "",
        color: config.color || "#000",
      };
      const duration =
        config.duration || (config.duration === null ? null : 10000);
      const Toast = config.card ? config.card : ToastCard;
      const toastConfig: UseToastOptions = {
        duration,
        position: config.position || "top-right",
        render: (toastProps) => (
          <Toast
            {...toastProps}
            width="auto"
            {...toastContent}
            id={String(toastProps.id)}
          />
        ),
      };
      return toast(toastConfig);
    },
    [toast]
  );

  const memo = useMemo(() => {
    const success: IUseShowParams = (overrides) => {
      return showToast({
        title: "Success",
        color: theme.colors.status.green,
        ...overrides,
      });
    };

    const info: IUseShowParams = (overrides) =>
      showToast({ title: "Please note", ...overrides });

    const warning: IUseShowParams = (overrides) =>
      showToast({
        title: "Warning",
        color: theme.colors.status.orange,
        ...overrides,
      });

    const error: IUseShowModalParams = (overrides) =>
      showModal({ color: theme.colors.status.red, ...overrides });

    return { success, info, warning, error };
  }, [showToast, showModal]);

  return memo;
};

export default useShow;
