/* eslint-disable no-template-curly-in-string */

import {
  ShareChannelType,
  ShareItemType,
} from "components/shared/ShareMenu/types";

export type ServiceEventName = "share_completed";

export interface FirebaseRequiredProps {
  event_type: FirebaseEventType;
  event_name: FirebaseEventType; // event_type and event_name should match.
  user_agent: string;
}

export type PayWallPageType =
  | "challenge_trial_multi_step"
  | "challenge_trial_single_step"
  | "free_trial_single_setup"
  | "free_trial_logged_in_variant_single_setup";

export type PayWallTriggerActionType = "free_trial_page" | "challenge";

export interface FirebaseEventAdditionalProps {
  page_name?: string;
  previous_page_name?: string;
  click_event_name?: string;
  view_name?: string;
  user_id?: string;
  email?: string;
  auth_provider?: "email" | "google" | "facebook" | "apple";
  organisation_id?: string;
  library_item_id?: string;
  library_item_type?: string;
  live_event_id?: string;
  dashboard_time_span?: string;
  dashboard_period?: string;
  dashboard_user_type?: string;
  dashboard_tab?: "Registered" | "Invited";
  invoice_url?: string;
  invitation_id?: string;
  inviter_user_id?: string;
  // share content attributes
  service_event_name?: ServiceEventName;
  deep_link_type?: "branch";
  share_link_id?: string;
  share_channel?: ShareChannelType;
  invite_link?: string;
  event_id?: string;
  group_id?: string;
  shop_product_id?: string;
  publisher_id?: string;
  playlist_id?: string;
  tracking_object_type?: ShareItemType;
  share_created_date?: number;
  sharer_user_id?: string;
  option_name?: string;
  before_click_state?: boolean;
  invited_emails?: string;
  number_of_invited_emails?: number;
  step?: "start" | "confirm" | "cancel" | "success" | "fail";
  plan_name?: "Teams" | "Enterprise";
  paywall_page_type?: PayWallPageType;
  paywall_action_trigger_type?: string;
  app?: boolean; // indicate whether web page is open in web-view or not.
}

export interface FirebaseEventProps
  extends FirebaseRequiredProps,
    FirebaseEventAdditionalProps {}

export type FirebaseEventType =
  | "click"
  | "page_view"
  | "search"
  | "login"
  | "sign_up"
  | "service_event"
  | "view";

export enum FirebaseEventPageName {
  DownloadAppPage = "download_app_page",
}

export enum FirebaseEvent {
  // Global
  PageView = "page_view",
  UserLogin = "user_login",
  UserSignup = "user_signup",
  LibraryItemTileClicked = "library_item_tile_clicked",
  LiveEventTileClicked = "live_event_tile_clicked",

  // auth button clicked event
  APPLE_SIGN_IN_CLICKED = "apple_sign_in_clicked",
  GOOGLE_SIGN_IN_CLICKED = "google_sign_in_clicked",
  FACEBOOK_SIGN_IN_CLICKED = "facebook_sign_in_clicked",
  SIGN_IN_CLICKED = "sign_in_clicked",
  SIGN_UP_CLICKED = "sign_up_clicked",
  APPLE_SIGN_UP_CLICKED = "apple_sign_up_clicked",
  GOOGLE_SIGN_UP_CLICKED = "google_sign_up_clicked",
  FACEBOOK_SIGN_UP_CLICKED = "facebook_sign_up_clicked",
}
