import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { useFirebaseEventTracking } from "hooks/useFirebaseEventTracking";
import { FirebaseEvent } from "lib/tracking/firebaseEventTracking";
import React, { useState } from "react";
import FormControlErrorMessage from "../ErrorMessage/FormControlErrorMessage";
import OrDivider from "../OrDivider";
import SignInWithApple from "../SignInWithApple";
import SignInWithFacebook from "../SignInWithFacebook";
import SignInWithGoogle from "../SignInWithGoogle";
import AuthLoginForm from "./AuthLoginForm";
import { VIEWS } from "./commonTypes";

interface AuthLoginViewProps {
  setAuthView: (view: VIEWS) => void;
}

const AuthLoginView = ({ setAuthView }: AuthLoginViewProps) => {
  const [providerError, setProviderError] = useState<string | undefined>(
    undefined
  );
  const { trackClick, trackEvent } = useFirebaseEventTracking();
  const logGASignInEvent = () => {
    trackEvent("login");
  };

  const onProviderAuthProcessComplete = (errorMessage?: string) => {
    const closedByUserError = errorMessage?.includes(
      "auth/popup-closed-by-user"
    );

    if (errorMessage && !closedByUserError) {
      setProviderError(errorMessage);
    } else {
      logGASignInEvent();
    }
  };

  return (
    <Box>
      <Box
        textAlign="center"
        maxW={["300px", "335px", "335px"]}
        marginX="auto"
        marginBottom={["45px", "35px", "35px"]}
      >
        <Heading
          as="h2"
          fontFamily="Brandon"
          fontSize="28px"
          lineHeight="34px"
          fontWeight={500}
          color="neutral.700"
          marginBottom={["20px", "22px", "22px"]}
        >
          Welcome to the largest free library of guided meditations on Earth.
        </Heading>
        <Flex flexWrap="wrap" justifyContent="center" textAlign="center">
          <Text
            fontFamily="Brandon"
            fontSize="16px"
            lineHeight="22px"
            fontWeight={400}
            color="neutral.600"
          >
            Don&apos;t have an account?
          </Text>
          <Button variant="unstyled" marginLeft="5px" height="auto">
            <Text
              fontFamily="Brandon"
              fontSize="16px"
              lineHeight="22px"
              fontWeight={500}
              color="teal.500"
              onClick={() => {
                setAuthView("SIGN_UP_OPTIONS_VIEW");
              }}
            >
              Sign up
            </Text>
          </Button>
        </Flex>
      </Box>
      <AuthLoginForm />
      <OrDivider marginY={["30px", "30px", "20px"]} />
      <VStack spacing="16px">
        <SignInWithApple
          onSubmit={() => {
            setProviderError(undefined);

            trackClick(FirebaseEvent.APPLE_SIGN_IN_CLICKED);
          }}
          onComplete={onProviderAuthProcessComplete}
        />
        <SignInWithGoogle
          onSubmit={() => {
            setProviderError(undefined);
            trackClick(FirebaseEvent.GOOGLE_SIGN_IN_CLICKED);
          }}
          onComplete={onProviderAuthProcessComplete}
        />
        <SignInWithFacebook
          onSubmit={() => {
            setProviderError(undefined);
            trackClick(FirebaseEvent.FACEBOOK_SIGN_UP_CLICKED);
          }}
          onComplete={onProviderAuthProcessComplete}
        />
        <FormControlErrorMessage
          textAlign="center"
          errorMessages={providerError}
        />
      </VStack>
    </Box>
  );
};

export default AuthLoginView;
